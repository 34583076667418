import React from 'react';
import Avatar from '@material-ui/core/Avatar';
//import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslate, Title } from 'react-admin';
import Button from '@material-ui/core/Button';
//import Modal from 'react-modal';
import Card from '@material-ui/core/Card';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import CardContent from '@material-ui/core/CardContent';
//import Typography from '@material-ui/core/Typography';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import BusinessIcon from '@material-ui/icons/Business';


import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';

class Profile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {},
      target: false,
      change: true,
    }
    this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
  }
  focusTextInput() {
    this.textInput.current.focus();
  }

  handle = (event) => {
    console.log("handle", event.target.value, this.state.user.displayName);
    // this.setState({company:event.target.value});
  }

  componentDidMount() {
    var user = firebase.auth().currentUser;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user: user, target: true });
      } else {
        console.log("user not");
      }
    }).bind(this);
  }

  navigate = () => {
    this.props.history.push('/Profile/edit');
  }

  render() {
    return (
      <div>
        {this.state.target == false ?
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 150 }}>
            {/* <CircularProgress color="secondary" /> */}
            <CircularProgress color="secondary" />
          </div>
          :
          <Card style={{ display: 'flex', justifyContent: 'center' }}>
            <CardContent >
              <Typography component="p" style={{ textAlign: 'center' }}>
                User Profile :
                </Typography>
              <br />
              {this.state.user.photoURL != null ?
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={this.state.user.photoURL}
                    style={{
                      width: 250, height: 250, borderBottomLeftRadius: 50,
                      borderBottomRightRadius: 50,
                      borderTopRightRadius: 50,
                      borderTopLeftRadius: 50, marginLeft: 0
                    }} />
                </div>
                :
                <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'black' }}>
                  <img src={require('../images/user_avatar.png')}
                    style={{
                      width: 250, height: 250, borderBottomLeftRadius: 50,
                      borderBottomRightRadius: 50,
                      borderTopRightRadius: 50,
                      borderTopLeftRadius: 50, marginLeft: 0
                    }} />
                </div>
              }
              <br />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p>{this.state.user.displayName != null ? firebase.auth().currentUser.displayName.toUpperCase() : "Name Not Provided"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <BusinessIcon style={{ marginTop: 17 }} />
                &nbsp;
                <p>{this.state.user.companyName != null ? 
                this.state.user.companyName.toUpperCase() : 
                <TextField value="Company Name Not Provided" InputProps={{ disableUnderline: true }} inputRef={this.textInput} style={{ width: 220 }}
                  onChange={(event) => {
                    this.handle(event)
                    this.setState({ change: false })
                  }
                  }
                />}</p>
     &nbsp;
     {this.state.change ?
                  <Button
                    variant="contained"
                    style={{ marginTop: 17, width: 50, height: 30, backgroundColor: '#04af86', color: 'white' }}
                    onClick={this.focusTextInput}
                  >
                    Edit
                   </Button>
                  :
                  <Button
                    variant="contained"
                    style={{ marginTop: 17, width: 50, height: 30, backgroundColor: '#04af86', color: 'white' }}
                  >
                    Save
                   </Button>
                }
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <MailOutlineIcon style={{ marginTop: 15 }} />
                  &nbsp;
                  <p>{this.state.user.email != null ? this.state.user.email : "Email Not Provided"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', marginRight: 20 }}>
                <PhoneIcon style={{ marginTop: 15 }} />
                  &nbsp;
                  <p>{this.state.user.phoneNumber != null ? this.state.user.phoneNumber : " Not Provided"}</p>
                                </div>
              <br /><br />
              <div style={{ display: 'flex', justifyContent: 'center' }} >
                <Button
                  variant="contained" style={{ backgroundColor: '#04af86', color: 'white', width: 200 }}
                  onClick={() => this.navigate()}
                >
                  Edit
                  </Button>
              </div>
            </CardContent>
          </Card>
        }
      </div>
    )
  }
}

export default Profile;