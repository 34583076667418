import React, { useState, useEffect, Component } from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import './App.css';

import authProvider from './authProvider';
//import themeReducer from './themeReducer';
import { Login, Layout, SignUp, NoLayout } from './layout';
//import { Dashboard } from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';

//import visitors from './visitors';
//import orders from './orders';
//import products from './products';
//import invoices from './invoices';
//import categories from './categories';
//import reviews from './reviews';


import dataProviderFactory from './dataProvider';
import fakeServerFactory from './fakeServer';

import * as firebase from 'firebase/app';
import 'firebase/auth';
const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }

    // Always fallback on english
    return englishMessages;
}, 'en');
class App extends Component {

    // const App = () => {

    constructor(props) {
        super(props);
        this.state = {
            dataProvider: "",
            fireAppInitialized: {},
            dataLoaded: false,
            uid: ""
        }
    }
    componentDidMount = () => {


        // useEffect(() => {

        let firebaseConfig = {
            apiKey: "AIzaSyA2lABjNHoBIT59FBdExUsCERc5RJw74ps",
            authDomain: "imagedataextract.firebaseapp.com",
            databaseURL: "https://imagedataextract.firebaseio.com",
            projectId: "imagedataextract",
            storageBucket: "imagedataextract.appspot.com",
            messagingSenderId: "685805190840",
            appId: "1:685805190840:web:86b3c58797f8dcd5e57e4d",
            measurementId: "G-2T0WHRW487"
        }

        this.fetchDataProvider();

        let fireApp = firebase.initializeApp(firebaseConfig);

        this.setState({ fireAppInitialized: fireApp })
        setTimeout(() => {
            let uid = null
            firebase.auth().onAuthStateChanged(async (user) => {
                console.log("this.firebaseConfig", user);
                if (user && user.uid) {
                    uid = user.uid
                    this.setState({ uid: uid })
                }
            })
            setTimeout(() => {
                this.setState({ dataLoaded: true })
            }, 2000);
        }, 3000);
    }
    fetchDataProvider = async () => {
        let restoreFetch;
        restoreFetch = await fakeServerFactory(
            process.env.REACT_APP_DATA_PROVIDER
        );
        const dataProviderInstance = await dataProviderFactory(
            process.env.REACT_APP_DATA_PROVIDER
        );
        this.setState({ dataProvider: dataProviderInstance })
        return restoreFetch;
    };

    // return (
    //     <div>
    //         <h2>{firebase.auth().currentUser?"user found":"User not found"}</h2>
    //     </div>)
    render() {
        console.log("this.state", this.state);

        if (!this.state.dataProvider && !this.state.dataLoaded) {
            return (
                <div className="loader-container">
                    <div className="loader">Loading...</div>
                </div>
            );
        }
        return (
            (this.state.dataLoaded && this.state.uid && this.state.uid.length > 0) ? <Admin
                title=""
                dataProvider={this.state.dataProvider}
                //customReducers={{ theme: themeReducer }}
                customRoutes={customRoutes}
                authProvider={authProvider}
                //dashboard={Dashboard}
                loginPage={Login}
                layout={Layout}
                i18nProvider={i18nProvider}
            >
                {/* <Resource name="customers" {...visitors} />
            <Resource
                name="commands"
                {...orders}
                options={{ label: 'Orders' }}
            />
            <Resource name="invoices" {...invoices} />
            <Resource name="products" {...products} />
            <Resource name="categories" {...categories} /> */}
                <Resource name="Login" {...Login} />
            </Admin>
                : this.state.dataLoaded && <Admin
                    title=""
                    dataProvider={this.state.dataProvider}
                    //customReducers={{ theme: themeReducer }}
                    customRoutes={customRoutes}
                    authProvider={authProvider}
                    //dashboard={Dashboard}
                    loginPage={Login}
                    layout={NoLayout}
                    i18nProvider={i18nProvider}
                >
                    {/* <Resource name="customers" {...visitors} />
            <Resource
                name="commands"
                {...orders}
                options={{ label: 'Orders' }}
            />
            <Resource name="invoices" {...invoices} />
            <Resource name="products" {...products} />
            <Resource name="categories" {...categories} /> */}
                    <Resource name="Login" {...Login} />
                </Admin>
        );
    };
}

export default App;
