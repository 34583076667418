import React from 'react';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { useTranslate, Title } from 'react-admin';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import _ from 'underscore';
//import LinkToRelatedCustomers from './LinkToRelatedCustomers';
//import segments from './data';
import moment from 'moment';

import * as firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/firestore';

class TemplateList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: '',
            dataArr: [],
            target: false
        }
    }

    async componentDidMount() {
        var user = firebase.auth().currentUser;
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                console.log("user id", user.uid);
                let arr = [];
                await firebase.firestore().collection("template").doc("tempItem").collection(user.uid).get().then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        console.log("doc", doc.data(), doc.id);
                        // doc.data() is never undefined for query doc snapshots
                        //console.log(doc.id, &quot; =&gt; &quot;, doc.data());
                        arr.push({ value: doc.data(), key: doc.id })
                    });
                });
                // console.log(&quot;arr.push&quot;, arr)
                this.setState({ dataArr: arr, target: true })
                console.log("dataArr", this.state.dataArr);
            }
        })
    }

    focusTextInput = (data) => {
        console.log("dat", data);
        this.props.history.push({
            pathname: '/editTemplate',
            state: { name: data }
        })
    }

    focusTextInputScan = (dataScan) => {
        console.log("dataScan", dataScan);
        this.props.history.push({
            pathname: '/ImageDataExtract',
            state: { name: dataScan }
        })
    }

    temp = () => {
        console.log("ll");
        this.props.history.push('/Admin');
    }

    render() {
        return (
            <div>
                {this.state.target && this.state.dataArr.length == 0 ?
                    <div style={{ textAlign: 'center', marginTop: 50, fontSize: 16 }}>
                        <p>No Template Found!!</p>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                style={{ width: 120, height: 60, backgroundColor: '#04af86', color: 'white' }}
                                onClick={this.temp}
                            >
                                Create Template
                            </Button>
                        </div>
                    </div>
                    : null}
                <div>
                    {this.state.dataArr ?
                        <Card>
                            <Table>
                                <TableBody>
                                    {this.state.dataArr.map(dataOne => (
                                        <TableRow>
                                            <TableCell style={{ borderBottom: "none", fontSize: 16 }}>
                                                <b style={{ color: '#0f5aa9' }}> {dataOne.value.name}</b><br />
                                                Description : {dataOne.value.description}<br />
                                                <Button
                                                    variant="contained"
                                                    style={{ width: 50, height: 30, backgroundColor: '#04af86', color: 'white', marginTop: 10 }}
                                                    onClick={() => this.focusTextInput(dataOne)}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    style={{ width: 150, height: 30, backgroundColor: '#04af86', color: 'white', marginTop: 10, marginLeft: 10 }}
                                                    onClick={() => this.focusTextInputScan(dataOne)}
                                                >
                                                    Click to Scan
                                                    </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Card>
                        : <p>Loading....</p>
                    }
                    {this.state.target ?
                        null
                        :
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 150 }}>
                            {/* <CircularProgress color="secondary" /> */}
                            <CircularProgress color="secondary" />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default TemplateList;

