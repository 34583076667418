import React from 'react';
import Avatar from '@material-ui/core/Avatar';
//import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
//import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PhoneIcon from '@material-ui/icons/Phone';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import CountryList from "../profile/CountryList";
import _ from "underscore";
//import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslate, Title } from 'react-admin';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
//import Modal from 'react-modal';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/firestore';


class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      code: '',
      firstName: '',
      lastName: '',
      v: '',
      search: '',
      newArr: [],
      confirmResult: null,
      change: false,
      changeOne: false,
      phone: '',
      error: '',
      setOpen: false,
      phoneNumber: '',
      expanded: '',
      setExpanded: false,
      setPass: false,
      selectedItem: {
        Name: "United States",
        phoneNumber: "1",
        flag: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAeCAMAAABpA6zvAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAj1QTFRF4bm96r/B6b/B3a6y5bO15bK1wltjyF1j8unr+vDx+vDwyHB2z3J31ZSZ3JicGS9dJTplITdjGjBdKDxnHjNhGzFfKT1oHzZjQjJXtzxFvT1EGjBedYKdXWyNFStaIDVih5OrRFV7FCpZLkJsjpmvdYKeGC5cWmyNQDFWuD5Hvj9GM0dwRlh9NEdwOEtzLEBqTF2BLUFrOUx0M0dvPVB2KT9pTFJ1HDJfeIWgFy1bJjpmhI+oNklxFi1bS12BY3KRFixbUmOF9/f5////aHaUVGSGIjdjPlF3Gi9dfYqkU2SGaHeVUGKFSk5ySlt/PlB3MkZvMUVuKz9qV2eJKz9pO093QDFVtjpCvDpBFSxbgo6nUWKEEilYJjtmj5qwOUxzEihYFCtaa3mXFCxbRDpfRFZ7KDxoJzxnXW2NKT5pYXCQQFJ5UF6BTl+DHjNgcH6aO051HjRhYnGQS1yAUGCD9fT1/vv8/vv7Fy1cfYmjT1+DiZSsZ3aUFS1cRkBkO011M0ZvMERtQVN5QlR6LEBrQ1V7Ok10N0pyL0VvQTBVtTc/uzc+coCcW2qLhJCpLkJri5atc4CcWGmLR0drHzRhITZiaXiWRVd8c4GdIDZiWGiKJTpmPE92YG+PL0NtKj5pJztnIDZjTlh76c/S8dbX8dXXdoOfXm2NiZWsEylZL0JskJuxd4SfFy5cW22OQDNXukZOwUZNwEZNJDllPVB3P1F4MkdwSzxfu0ZOzdLczNHbzdHbzNDb1tPb8NXXWNrergAAAA90Uk5T/v7+/v7+/v7+/v7+/v7+6a2FXwAAAAFiS0dEQ2fQDWIAAAAJcEhZcwAAAEgAAABIAEbJaz4AAAITSURBVDjLY+AXEOQXEhbhFxUT5RcRFuIXFODnFxCXkJRCAwzSMrJy8gqKSsoqykqKCvJysqpqaqrqGppaaICBX1tZR1dP38DQyNBAX09XR9nYxMTY1IyBkQkVMPDzm1sYWFpZ29jaWFtZ2lmYq9nbqzk4OjmjAQZpF1dpNwt3D0NPQw93CzdpL29zc28fX2YWVlTAwO/nryzmFRAYFBwUGOAlpuzvFxLiFxoWHoEGGPgjpaOiY2Lj4hPi42JjoqOkE5OS5JJT2NjRAIOQY2paeoZNpn6WfqZNRnpaqmNsrGN2DgcnFypg4M/KzXPLLyjUV9UvLMh3y8stKiwsKi4pLStHBQz8FaKVVYmx1XpKetWxiVWVog41Nba1ddw8aICBv77Bv7HJsLmltaXZsKnRv6Gtvb2to7OrGw0w8Pf0ysn3NSf2T+hPbO6Tl+udqKY2cdJkXj40wMA/Zeq06XnpM7TztGek502fNjNv1qxCaSwBzp8+e9YcMeG58+bPmyssNmfW7AXFxQsWLlq8ZCkqYOBftlxJfkXqylWrV61MXSGvtHzN2rVr1q3fsHETKmAQMNLdHLtFefP8rfM3K2+J3axrJCBgtG37DgyFO3ft2rlz1+6dO/fs3LkbxATjvfuWolvtTCRg4CMSMHQTCRh4iAQM5UQCBi4iAQM7kYAhgkjAwEokID7AmYgEDFpEAgYpIgEA2hc6qEvsr/QAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTMtMTAtMDdUMTM6MTU6MTUrMDI6MDDoZk8yAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDEzLTEwLTA3VDEzOjE1OjE1KzAyOjAwmTv3jgAAAABJRU5ErkJggg==",
      }
    }
  }

  state = { isSignedIn: false }
  uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    width: 240,
    callbacks: {
      signInSuccess: () => false
    }
  }

  componentDidMount = () => {
    firebase.auth().onAuthStateChanged(user => {
      this.setState({ isSignedIn: !!user })
      if (user) {
        this.props.history.push('/ImageDataExtract');
      }
    })

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container",
      {
        size: "invisible"
        // other options
      });
  }

  handleChange = (panel) => (event, isExpanded) => {
    //var setExpanded = '';
    //setExpanded(isExpanded ? panel : false);
    this.setState({ expanded: panel, setExpanded: !this.state.setExpanded })
  };

  handle = (event) => {
    this.setState({ v: event.target.value })
  }


  handlePhone = (event) => {
    this.setState({ phone: event.target.value })
  }

  phoneClick = () => {
    this.setState({ change: true, setOpen: true })
  }

  onClick = () => {
    //const phoneNumber = '+918607151081';
    const phoneNumber = '+' + this.state.selectedItem.phoneNumber + this.state.phone;
    //var testVerificationCode = "123456";
    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(confirmResult => {
        this.setState({ confirmResult, changeOne: true })
      })
      .catch(error => {
        console.log("error", error);
      });
  }

  login = () => {
    const { confirmResult, phone, selectedItem } = this.state;

    confirmResult.confirm(this.state.v).then(function (result) {
      var fullPhoneNumber = '+' + selectedItem.phoneNumber + phone;
      // firebase.database().ref('users/' + result.user.uid).update({
      var obj = {
        userId: result.user.uid,
        firstName: '',
        lastName: '',
        email: '',
        code: '',
        phoneNumber: fullPhoneNumber,
      }
      // });

      firebase.firestore().collection("users").doc(result.user.uid)
        .set(obj).then(function () {
          console.log("Document successfully updated!");

        });

    }).catch(error => {
      this.setState({ error: 'Enter Code is Incorrect!' })
      console.log("error", error);
    });
  }

  sign = () => {
    this.props.history.push('/SignUp');
  }

  handleClickEmail = (e) => {
    this.setState({ email: e.target.value });
  }

  handleClickPwd = (e) => {
    this.setState({ password: e.target.value });
  }

  handleClick = () => {
    //console.log("one");
    firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((res) => {
        this.props.history.push('/ImageDataExtract');
      }).catch((error) => {
        console.log(error);
        alert("Please Enter Right Email or Password !");
      })
  }

  handleClose = () => {
    this.setState({ setOpen: false, setPass: false })
  };
  handlePassword = async () => {
    await firebase.auth().sendPasswordResetEmail(this.state.email)
    setTimeout(() => {
      this.setState({ setPass: false })
    }, 2000);
  }
  onSelected = (data, dataOne, dataTwo) => {
    console.log("selected", data, dataOne, dataTwo, this.state);
    this.state.selectedItem.phoneNumber = data;
    this.state.selectedItem.Name = dataTwo;
    this.state.selectedItem.flag = dataOne;
    this.setState({ setExpanded: false });
    //this.setState({ selectedItem.phoneNumber : data });
    //return selected;
  }

  searchText = (event) => {

    console.log("searchText", event.target.value);
    this.setState({ search: event.target.value });

    var newArray = [];
    _.each(CountryList, (key, item) => {
      //var patt = /this.state.search/g;

      // if(key.name.common.toUpperCase() === event.target.value.toUpperCase()){
      var string = key.name.common.toUpperCase();
      var substring = event.target.value.toUpperCase();
      var bool = string.includes(substring);

      if (bool == true) {
        newArray.push({ Name: key.name.common, flag: key.flag, phoneNumber: key.callingCode[0] })
      }
    })
    this.setState({ newArr: newArray });
  }


  render() {

    var contList = [];
    _.each(CountryList, (key, item) => {
      contList.push({ Name: key.name.common, flag: key.flag, phoneNumber: key.callingCode[0] })
    })
    console.log("contList", contList);
    console.log("this.state", this.state);

    const body = (
      <div style={{ backgroundColor: 'white', position: 'absolute', top: 120, left: 520, border: '2px solid #04af86', paddingLeft: 10, paddingRight: 10 }}>
        <h3 id="simple-modal-title" style={{ fontFamily: 'Rockwell', display: 'flex', justifyContent: 'center' }}>Sign in with Phone Number :</h3>
        <p id="simple-modal-description">
          <ExpansionPanel style={{ width: 350 }} expanded={this.state.expanded === 'panel1' && this.state.setExpanded == true} onChange={this.handleChange('panel1')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>Select Phone Number Country </Typography>
           &nbsp;


        </ExpansionPanelSummary>

            <div style={{ height: 200, overflowY: 'scroll' }}>

              <div style={{ marginTop: 5, marginLeft: 10, marginRight: 10 }}>
                <TextField
                  variant="outlined"
                  label="Search"
                  fullWidth
                  value={this.state.search}
                  onChange={this.searchText}
                //style={{position:'fixed'}}
                />

              </div>
              <br />

              {this.state.search === '' ?
                <div>

                  {contList.map(dataOne => (
                    <ExpansionPanelDetails >
                      <Typography onClick={() => this.onSelected(dataOne.phoneNumber, dataOne.flag, dataOne.Name.toLocaleUpperCase())}>

                        <img src={dataOne.flag} />
   &nbsp;&nbsp;
   {dataOne.Name.toLocaleUpperCase()}
   &nbsp;&nbsp;
   ( + {dataOne.phoneNumber} )

        </Typography>
                    </ExpansionPanelDetails>
                  ))}


                </div>

                :
                <div>

                  {this.state.newArr.map(dataOn => (
                    <ExpansionPanelDetails >
                      <Typography onClick={() => this.onSelected(dataOn.phoneNumber, dataOn.flag, dataOn.Name.toLocaleUpperCase())}>

                        <img src={dataOn.flag} />
   &nbsp;&nbsp;
   {dataOn.Name.toLocaleUpperCase()}
   &nbsp;&nbsp;
   ( + {dataOn.phoneNumber} )

        </Typography>
                    </ExpansionPanelDetails>
                  ))}


                </div>

              }

            </div>
          </ExpansionPanel>
          <br />
          <Typography style={{ display: 'flex', justifyContent: 'center' }}><b style={{ fontSize: 16 }}>Selected :</b> &nbsp; <img src={this.state.selectedItem.flag} style={{ height: 20, width: 30, fontSize: 16 }} /> &nbsp; {this.state.selectedItem.Name} ( + {this.state.selectedItem.phoneNumber})</Typography>

          <div style={{ marginTop: 10, display: 'flex', justifyContent: 'center' }}>
            <Grid container >
              <Grid item xs={12} sm={12}>
                <Grid container >
                  <Grid item xs={12} sm={3}>
                    <p style={{ marginTop: 22 }}> + ({this.state.selectedItem.phoneNumber}) - </p>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      //style={{marginRight:80}}
                      //variant="outlined"
                      label="Enter Phone Number"
                      value={this.state.phone}
                      onChange={this.handlePhone}
                    />
                  </Grid>
                </Grid>
              </Grid>

            </Grid>

          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={this.onClick}
              style={{ display: 'flex', justifyContent: 'center', marginTop: 18, height: 30, borderRadius: 5, backgroundColor: '#04af86', color: 'white', fontSize: 12, fontWeight: 600 }}
            >
              <p >Send Code</p>
            </Button>
          </div>
        </p>

        {this.state.changeOne ?
          <p id="simple-modal-description">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              A verification code sent to this phone number.
</div>

            <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
              <Grid container >
                <Grid item xs={12} sm={6} style={{ marginLeft: 10 }}>
                  <TextField
                    //variant="outlined"
                    label="Enter Verification Code"
                    value={this.state.v}
                    onChange={this.handle}
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button onClick={this.laare}
                    style={{ marginTop: 18, height: 30, width: 100, borderRadius: 5, backgroundColor: '#04af86', color: 'white', fontSize: 12, fontWeight: 600 }}
                  >
                    <p>Sign In</p>
                  </Button>

                </Grid>

              </Grid>

            </div>


          </p>

          : null}

        <p style={{ color: 'red', marginLeft: 10 }} id="simple-modal-description">
          {this.state.error}
        </p>

        <br /><br />
      </div>
    );

    return (
      <div>
        <div style={{ backgroundImage: `url(${require("./app_bg.jpg")})`, position: 'fixed', zIndex: 0, top: 0, left: 0, right: 0, bottom: 0 }}> </div>
        <Grid container>
          <Grid item xs={5}>
            <div style={{
              backgroundImage: `url(${require("./dg_white.png")})`,
              width: '100%',
              position: 'relative',
              backgroundRepeat: 'no-repeat',
              paddingTop: '96%',
              backgroundSize: '330px',
              backgroundPosition: 'center right'
            }}></div>
            {/* <img src= "../images/dg_white.png"/> */}
          </Grid>
          <Grid item xs={7}>

            <Container component="main" maxWidth="xs">
              <div style={{ backgroundColor: 'white', position: 'relative', paddingLeft: 30, paddingRight: 30, borderRadius: 10, width: '100%' }}>
                <div style={{ marginTop: 50, padding: 10 }}>
                  <span style={{ fontSize: 16, padding: 10, float: 'left', fontWeight: 600 }}>Sign in</span>
                  <span style={{ cursor: 'pointer ', color: '##589cef', float: 'right', fontSize: 14, padding: 10 }}>or <span onClick={this.sign} style={{ color: '#589cef' }}>create an account</span></span>
                </div>
                <div style={{ paddingBottom: 20, paddingTop: 20 }}>
                  <StyledFirebaseAuth
                    uiConfig={this.uiConfig}
                    firebaseAuth={firebase.auth()}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: -20 }}>
                  <Button id="recaptcha-container" onClick={this.phoneClick}
                    style={{ height: 40, width: '59%', borderRadius: 5, backgroundColor: '#B52A2A', color: 'white', fontSize: 12, fontWeight: 600 }}
                  >
                    <PhoneIcon style={{ color: 'white' }} /> <p style={{ marginLeft: 10 }}>Sign in with Phone Number</p>
                  </Button>
                  <Modal
                    open={this.state.setOpen}
                    onClose={this.handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    {body}
                  </Modal>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <span style={{ padding: 12 }}>or</span>
                </div>
                <form noValidate style={{ paddingBottom: 20 }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    //autoFocus
                    onChange={this.handleClickEmail}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={this.handleClickPwd}
                  />
                  {/* <Button
                    //type="submit"
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: '#04af86', color: 'white' }}
                    // color="#04af86"
                    //className={classes.submit}
                    onClick={this.handleClick}
                  >
                    Sign In
                  </Button> */}
                    <Grid container style={{ marginTop: 10, }}>
                    <Grid item xs={2} sm={2} >
                      <Checkbox
                        defaultChecked
                        color="default"
                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                      >
                      </Checkbox>
                    </Grid>
                    <Grid item xs={2} sm={4} >
                      <p style={{ fontSize: 12, color: "light grey", marginTop: 14, marginLeft: -25 }}>  Remember me</p>
                    </Grid>
                    <Grid item xs={2} sm={6} >

                      <Button
                        //type="submit"
                        fullWidth
                        variant="contained"
                        style={{ backgroundColor: '#5cb85c', color: 'white' }}
                        // color="#f79403"
                        //className={classes.submit}
                        onClick={this.handleClick}
                      >
                        Sign In
                  </Button>
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                    <Grid item >
                      <span style={{ cursor: 'pointer ', color: '##589cef', float: 'center', fontSize: 14, padding: 10 }}>
                        <span onClick={() => this.setState({ setPass: true })} style={{ color: '#589cef' }}>
                          Forgot your Password</span></span>

                    </Grid>
                  </Grid>
                </form>
              </div>
              <Modal
                open={this.state.setPass}
                onClose={this.handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <div style={{ width: 500, backgroundColor: 'white', position: 'absolute', top: 120, left: '30%', border: '2px solid #04af86', paddingLeft: 10, paddingRight: 10 }}>
                  <form noValidate>
                    <TextField
                      // variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      //autoFocus
                      onChange={this.handleClickEmail}
                    />
                    <Grid container style={{ marginTop: 20, marginBottom: 20, display: 'flex', justifyContent: 'center' }}>
                      < Grid item xs={2} sm={6} >
                        <Button
                          //type="submit"
                          fullWidth
                          variant="contained"
                          style={{ backgroundColor: '#5cb85c', color: 'white' }}
                          // color="#04af86"
                          //className={classes.submit}
                          onClick={this.handlePassword}
                        >
                          Send Email
                      </Button>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </Modal>
            </Container>
          </Grid>
        </Grid>
      </div>
    )
  }
};




export default Login;
