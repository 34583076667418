import React, { FC, useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import LabelIcon from '@material-ui/icons/Label';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink } from 'react-admin';
import InvoiceIcon from '@material-ui/icons/LibraryBooks';
import ReviewIcon from '@material-ui/icons/Comment';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import StarBorder from '@material-ui/icons/StarBorder';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ListIcon from '@material-ui/icons/List';
import WorkOrderDetail from '../workOrderList/workOrderDetail';
//import visitors from '../visitors';
//import orders from '../orders';
//import invoices from '../invoices';
//import products from '../products';
//import categories from '../categories';
//import reviews from '../reviews';
import SubMenu from './SubMenu';
import { AppState } from '../types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    menu: {
        backgroundColor: 'red',
    },
};
type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const [state, setState] = useState({
        menuCatalog: false,
        menuSales: false,
        menuCustomers: false,
    });
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    const [opened, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!opened);
    };

    const text = {
        color: "black"
    };

    return (
        <div style={{marginTop:30, height:"100%"}} >
            {' '}
            {/* <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            <SubMenu
                handleToggle={() => handleToggle('menuSales')}
                isOpen={state.menuSales}
                sidebarIsOpen={open}
                name="pos.menu.sales"
                icon={<orders.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/commands`}
                    primaryText={translate(`resources.commands.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<orders.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/invoices`}
                    primaryText={translate(`resources.invoices.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<invoices.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu> */}
            {/* <SubMenu
                handleToggle={() => handleToggle('menuCatalog')}
                isOpen={state.menuCatalog}
                sidebarIsOpen={open}
                name="pos.menu.catalog"
                icon={<products.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/products`}
                    primaryText={translate(`resources.products.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<products.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/categories`}
                    primaryText={translate(`resources.categories.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<categories.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu> */}
            {/* <SubMenu
                handleToggle={() => handleToggle('menuCustomers')}
                isOpen={state.menuCustomers}
                sidebarIsOpen={open}
                name="pos.menu.customers"
                icon={<visitors.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/customers`}
                    primaryText={translate(`resources.customers.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<visitors.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/segments`}
                    primaryText={translate(`resources.segments.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<LabelIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>  */}
            {/* <MenuItemLink
                to={`/reviews`}
                primaryText={translate(`resources.reviews.name`, {
                    smart_count: 2,
                })}
                leftIcon={<reviews.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            /> */}
            {/* {isXSmall && (
                <MenuItemLink
                    to="/configuration"
                    primaryText={translate('pos.configuration')}
                    leftIcon={<SettingsIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )} */}
            {isXSmall && logout}

            <MenuItemLink
                to={`/ImageDataExtract`}
                primaryText={"Image Data Extract"}
                leftIcon={<WallpaperIcon style={{marginLeft:-5, marginTop:5, height: 40, width: 40 }} />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/WorkOrderList`}
                primaryText={"Work Order"}
                leftIcon={<ListAltIcon style={{marginLeft:-5, marginTop:5, height: 40, width: 40 }} />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />

            <MenuItemLink
                to={`/Profile`}
                primaryText={"Profile"}
                leftIcon={<AccountBoxIcon style={{marginLeft:-5, marginTop:5, height: 40, width: 40 }} />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />

            <ListItem button onClick={handleClick}>
                <Tooltip title="Admin">
                    <ListItemIcon>
                        <SupervisorAccountIcon style={{marginLeft:-5, marginTop:5, height: 40, width: 40 }} />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText style={{color:"#63676e", marginLeft:-10}}  primary="Admin" />
                {!opened ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={!opened} timeout="auto" unmountOnExit>
                <MenuItemLink
                    to={`/Admin`}
                    primaryText={"New Template"}
                    leftIcon={<AddBoxIcon style={{marginLeft:-5, marginTop:5, height: 30, width: 30 }} />}
                    onClick={onMenuClick}
                    // sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/templateList`}
                    primaryText={"Template List"}
                    leftIcon={<ListIcon style={{marginLeft:-5, marginTop:5, height: 30, width: 30 }} />}
                    onClick={onMenuClick}
                    // sidebarIsOpen={open}
                    dense={dense}
                />
            </Collapse>
        </div>
    );
};
export default withStyles(styles) (Menu)
// export default Menu;
