import React from 'react';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { useTranslate, Title } from 'react-admin';
import Button from '@material-ui/core/Button';
//import Modal from 'react-modal';
import PhoneIcon from '@material-ui/icons/Phone';
import Modal from '@material-ui/core/Modal';
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import FacebookIcon from '@material-ui/icons/Facebook';

import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import PersonIcon from '@material-ui/icons/Person';
//import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import CountryList from "./CountryList";
import _ from "underscore";
import Avatar from '@material-ui/core/Avatar';
//import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
//import {Button, Collapse} from 'react-bootstrap';
//import Dropdown, { DropdownTrigger, DropdownContent} from 'react-simple-dropdown';
//import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { GoogleLogin, GoogleLogout } from 'react-google-login';

import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/firestore';

const clientId ="685805190840-6g2c989jk3m1smjhfoee5r1teklms69f.apps.googleusercontent.com"

class EditProfile extends React.Component{


     constructor(props){
  super(props);
  this.state={
  firstName:'',
  first:'',
  lastName:'',
  email:'',
  password:'',
  search:'',
  changeOne:false,
  error:'',
  v:'',
  change:false,
  newArr:[],
  phone: '',
  expanded:'',
  setOpen:false,
  setExpanded:false,
  user:{},
   isLogined: false,
      accessToken: '',
  selectedItem: {
        Name: "United States",
        phoneNumber: "1",
        flag: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAeCAMAAABpA6zvAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAj1QTFRF4bm96r/B6b/B3a6y5bO15bK1wltjyF1j8unr+vDx+vDwyHB2z3J31ZSZ3JicGS9dJTplITdjGjBdKDxnHjNhGzFfKT1oHzZjQjJXtzxFvT1EGjBedYKdXWyNFStaIDVih5OrRFV7FCpZLkJsjpmvdYKeGC5cWmyNQDFWuD5Hvj9GM0dwRlh9NEdwOEtzLEBqTF2BLUFrOUx0M0dvPVB2KT9pTFJ1HDJfeIWgFy1bJjpmhI+oNklxFi1bS12BY3KRFixbUmOF9/f5////aHaUVGSGIjdjPlF3Gi9dfYqkU2SGaHeVUGKFSk5ySlt/PlB3MkZvMUVuKz9qV2eJKz9pO093QDFVtjpCvDpBFSxbgo6nUWKEEilYJjtmj5qwOUxzEihYFCtaa3mXFCxbRDpfRFZ7KDxoJzxnXW2NKT5pYXCQQFJ5UF6BTl+DHjNgcH6aO051HjRhYnGQS1yAUGCD9fT1/vv8/vv7Fy1cfYmjT1+DiZSsZ3aUFS1cRkBkO011M0ZvMERtQVN5QlR6LEBrQ1V7Ok10N0pyL0VvQTBVtTc/uzc+coCcW2qLhJCpLkJri5atc4CcWGmLR0drHzRhITZiaXiWRVd8c4GdIDZiWGiKJTpmPE92YG+PL0NtKj5pJztnIDZjTlh76c/S8dbX8dXXdoOfXm2NiZWsEylZL0JskJuxd4SfFy5cW22OQDNXukZOwUZNwEZNJDllPVB3P1F4MkdwSzxfu0ZOzdLczNHbzdHbzNDb1tPb8NXXWNrergAAAA90Uk5T/v7+/v7+/v7+/v7+/v7+6a2FXwAAAAFiS0dEQ2fQDWIAAAAJcEhZcwAAAEgAAABIAEbJaz4AAAITSURBVDjLY+AXEOQXEhbhFxUT5RcRFuIXFODnFxCXkJRCAwzSMrJy8gqKSsoqykqKCvJysqpqaqrqGppaaICBX1tZR1dP38DQyNBAX09XR9nYxMTY1IyBkQkVMPDzm1sYWFpZ29jaWFtZ2lmYq9nbqzk4OjmjAQZpF1dpNwt3D0NPQw93CzdpL29zc28fX2YWVlTAwO/nryzmFRAYFBwUGOAlpuzvFxLiFxoWHoEGGPgjpaOiY2Lj4hPi42JjoqOkE5OS5JJT2NjRAIOQY2paeoZNpn6WfqZNRnpaqmNsrGN2DgcnFypg4M/KzXPLLyjUV9UvLMh3y8stKiwsKi4pLStHBQz8FaKVVYmx1XpKetWxiVWVog41Nba1ddw8aICBv77Bv7HJsLmltaXZsKnRv6Gtvb2to7OrGw0w8Pf0ysn3NSf2T+hPbO6Tl+udqKY2cdJkXj40wMA/Zeq06XnpM7TztGek502fNjNv1qxCaSwBzp8+e9YcMeG58+bPmyssNmfW7AXFxQsWLlq8ZCkqYOBftlxJfkXqylWrV61MXSGvtHzN2rVr1q3fsHETKmAQMNLdHLtFefP8rfM3K2+J3axrJCBgtG37DgyFO3ft2rlz1+6dO/fs3LkbxATjvfuWolvtTCRg4CMSMHQTCRh4iAQM5UQCBi4iAQM7kYAhgkjAwEokID7AmYgEDFpEAgYpIgEA2hc6qEvsr/QAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTMtMTAtMDdUMTM6MTU6MTUrMDI6MDDoZk8yAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDEzLTEwLTA3VDEzOjE1OjE1KzAyOjAwmTv3jgAAAABJRU5ErkJggg==",
      },
      
  }

  this.login = this.login.bind(this);
    this.handleLoginFailure = this.handleLoginFailure.bind(this);
 }

 state = { isSignedIn: false }
  uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      
    ],
    callbacks: {
      signInSuccess: () => false
    }
  }

  componentDidMount() {

         //var user = firebase.auth().currentUser;

          firebase.auth().onAuthStateChanged((user) => {
            this.setState({ isSignedIn: !!user })
           

  if (user) {

    console.log("user user edit",user);

    this.setState({user:user});

  }else{

    console.log("user not");

  }

   }).bind(this);

setTimeout(function() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container",
    {
       size:"invisible"
        // other options
    });

},8000);

    }

    phoneClick = () => {

    this.setState({ change :true,setOpen:true })

  }

   handleClose = () => {
    this.setState({setOpen:false})
  };

  handleChange = (panel) => (event, isExpanded) => {
      //var setExpanded = '';
    //setExpanded(isExpanded ? panel : false);
    
    this.setState({expanded : panel ,setExpanded : !this.state.setExpanded })
  };

 handleClickEmail = (e) => {

        this.setState({email : e.target.value});

    }

    handleClickPwd = (e) => {

        this.setState({password : e.target.value});

    }

    handleClickFirst = (e) => {

        this.setState({firstName : e.target.value});

    }

    handleClickfirstName = (e) => {

        this.setState({first : e.target.value});

    }

    handleClicklastName = (e) => {

        this.setState({lastName : e.target.value});

    }

    handleClickNum = (e) => {

        this.setState({phoneNumber : e.target.value});

    }

    handleClickCancel = (e) => {

       this.props.history.push('/Profile');

    }

    onSelected = (data,dataOne,dataTwo) => {
        console.log("selected",data,dataOne,dataTwo,this.state);
        this.state.selectedItem.phoneNumber = data;
        this.state.selectedItem.Name = dataTwo;
        this.state.selectedItem.flag = dataOne;
        this.setState({setExpanded:false});
    //this.setState({ selectedItem.phoneNumber : data });
    //return selected;
  }

   handle =(event)=>{

    console.log("event",event.target.value);
    this.setState({v:event.target.value})

  }

  handlePhone =(event)=>{

    console.log("event",event.target.value);
    this.setState({phone:event.target.value})

  }

   responseFacebook = (response) => {
      console.log("facebook success",response);

      let splitFullName = response.name.split(" ");

      console.log("fb splitFullName",splitFullName[0],splitFullName[1]);

       const credential = firebase.auth.FacebookAuthProvider.credential(response.accessToken);


          firebase.auth().currentUser.linkWithCredential(credential);
            var userf = firebase.auth().currentUser;


            userf.updateProfile({ displayName: response.name, photoURL: response.picture.data.url }).then(function () {
            }, function (error) {
              console.log("Error update displayName.");
            });
            firebase.firestore().collection("users").doc(this.state.user.uid)
    .set({
     userId: this.state.user.uid,
        firstName: splitFullName[0],
        lastName: splitFullName[1],
        email: response.email,
        //code: this.state.password,
        profilePic: response.picture.data.url
},{ merge: true }).then(()=> {
    console.log("Document successfully updated!");
    setTimeout(()=> {
this.props.history.push('/Profile');
    },2000)
});

  
   }

   

   handleLoginFailure = (response) => {
    //alert('Failed to log in')
    console.log("error",response);
  }

  login = (response) => {

    console.log("res",response);

    const credential = firebase.auth.GoogleAuthProvider.credential(response.tokenId, response.accessToken);
  // console.log("credential",credential);
   firebase.auth().currentUser.linkWithCredential(credential);

var userf = firebase.auth().currentUser;
 userf.updateProfile({ displayName: response.profileObj.givenName + " " + response.profileObj.familyName, photoURL: response.profileObj.imageUrl }).then(function () {
      }, function (error) {
        console.log("Error update displayName.");
      });
   firebase.firestore().collection("users").doc(this.state.user.uid)
    .set({
     userId: this.state.user.uid,
        firstName: response.profileObj.givenName,
        lastName: response.profileObj.familyName,
        email: response.profileObj.email,
        //code: this.state.password,
        profilePic: response.profileObj.imageUrl
},{ merge: true }).then(()=> {
    console.log("Document successfully updated!");
    setTimeout(()=> {
this.props.history.push('/Profile');
    },2000)
});

 
   
  }

  

  onClick = () => {
    console.log("number",this.state.selectedItem.phoneNumber,this.state.phone);
    
     const phoneNumber = '+' + this.state.selectedItem.phoneNumber + this.state.phone;
    
    const appVerifier = window.recaptchaVerifier;

    
var provider = new firebase.auth.PhoneAuthProvider();

provider.verifyPhoneNumber(phoneNumber, appVerifier)
// firebase
    // .auth()
    // .signInWithPhoneNumber(phoneNumber, appVerifier)
    .then(confirmResult => {

      console.log("confirmResult",confirmResult);
      this.setState({ confirmResult , changeOne :true})

    })
    .catch(error => {
      //console.log("error",error);
    });
}

saveDisplay = () => {

  console.log("this.state.user.uid",this.state.user.uid,this.state.firstName);
  if(this.state.firstName == ""){
console.log("Display Name Empty!");

  }else{

    firebase.auth().currentUser.updateProfile({displayName:this.state.firstName});

    console.log("this.state.firstName",this.state.firstName);
  firebase.firestore().collection("users").doc(this.state.user.uid)
    .set({
    displayName: this.state.firstName
},{ merge: true }).then(()=> {
    console.log("Document successfully updated!");
    setTimeout(()=> {
this.props.history.push('/Profile');
    },2000)
});


  }

}

submit_Click = () => {

  console.log("this.state.user.uid",this.state.user.uid);

  if(this.state.first == "" && this.state.lastName == "" && this.state.email == "" && this.state.password == ""){
console.log("Empty!");
alert("Please fill all required Fields!");

  }else{
    var credential = firebase.auth.EmailAuthProvider.credential(this.state.email, this.state.password);


    firebase.auth().currentUser.linkWithCredential(credential).then((crr) => {
          var userf = firebase.auth().currentUser;
          userf.sendEmailVerification().then(() => {
            alert(
              'Verification Email Sent!')
          }, function (error) {
            console.log("An error happened.", error)
          });

    userf.updateProfile({displayName:this.state.first + ' ' + this.state.lastName, email:this.state.email});

   // console.log("this.state.firstName",this.state.firstName);
  firebase.firestore().collection("users").doc(this.state.user.uid)
    .set({
    //displayName: this.state.first + ' ' + this.state.lastName,
    firstName: this.state.first,
    lastName: this.state.lastName,
    email:this.state.email,
    code:this.state.password
},{ merge: true }).then(()=> {
    console.log("Document successfully updated!");
    setTimeout(()=> {
this.props.history.push('/Profile');
    },2000)
});


  })
  }



}


laare = () => {

  const { confirmResult,phone,selectedItem,user } = this.state;

  //confirmResult.confirm(this.state.v).then(function (result) {
console.log("confirmResult",confirmResult);
console.log("this.state.v",this.state.v);

    var credential = firebase.auth.PhoneAuthProvider.credential(confirmResult, this.state.v);
    console.log("credential",credential);

    firebase.auth().currentUser.linkWithCredential(credential);


        var fullPhoneNumber = '+' + selectedItem.phoneNumber + phone ;
        console.log("fullPhoneNumber",fullPhoneNumber);



  firebase.firestore().collection("users").doc(user.uid)
    .set({
    phoneNumber: fullPhoneNumber
}, { merge: true }).then(function() {
    console.log("Document successfully updated!");
    
});

this.props.history.push('/Profile');

//       }).catch(error => {
//         this.setState({error:'Enter Code is Incorrect!'})
//       console.log("error",error);
//     });

}

 

  searchText = (event) => {

    console.log("searchText",event.target.value);
    this.setState({search:event.target.value});

     var newArray = [];
    _.each(CountryList, (key, item) => {
      //var patt = /this.state.search/g;

     // if(key.name.common.toUpperCase() === event.target.value.toUpperCase()){
        var string = key.name.common.toUpperCase();
        var substring = event.target.value.toUpperCase();
        var bool = string.includes(substring);

        console.log("bool",bool);

        if(bool == true){

          newArray.push({ Name: key.name.common, flag: key.flag, phoneNumber: key.callingCode[0] })

        }

    })

    console.log("newArray",newArray);

    this.setState({newArr:newArray});

  }

  

  
    render(){

         console.log("this.state",this.state);

         

    var contList = [];
    _.each(CountryList, (key, item) => {
      contList.push({ Name: key.name.common, flag: key.flag, phoneNumber: key.callingCode[0] })
    })

    console.log("list",contList);


    const body = (
    <div style={{backgroundColor:'white',position:'absolute',top:100,left:520,border:'2px solid #04af86',paddingLeft:10,paddingRight:10}}>
      <h3 id="simple-modal-title" style={{fontFamily:'Rockwell',display: 'flex',  justifyContent:'center'}}>Sign in with Phone Number :</h3>
      <p id="simple-modal-description">
        <ExpansionPanel style={{width:350}} expanded={this.state.expanded === 'panel1' && this.state.setExpanded == true} onChange={this.handleChange('panel1')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography>Select Phone Number Country </Typography>
           &nbsp;
           

        </ExpansionPanelSummary>
        
<div style={{height:200,overflowY: 'scroll'}}>

  <div style={{marginTop:5,marginLeft:10,marginRight:10}}>
   <TextField
            variant="outlined"
              label="Search"
             fullWidth
             value={this.state.search}
             onChange={this.searchText}
            //style={{position:'fixed'}}
            />

            </div>
<br/>
              
              {this.state.search === '' ?
<div>

  {contList.map(dataOne => (
                 <ExpansionPanelDetails >
          <Typography onClick={() => this.onSelected(dataOne.phoneNumber,dataOne.flag,dataOne.Name.toLocaleUpperCase())}>
          
   <img src={dataOne.flag} />  
   &nbsp;&nbsp;
   {dataOne.Name.toLocaleUpperCase()} 
   &nbsp;&nbsp;
   ( + {dataOne.phoneNumber} ) 

        </Typography>
         </ExpansionPanelDetails>
          ))}
               

          </div>

              :
<div>

   {this.state.newArr.map(dataOn => (
                 <ExpansionPanelDetails >
          <Typography onClick={() => this.onSelected(dataOn.phoneNumber,dataOn.flag,dataOn.Name.toLocaleUpperCase())}>
          
   <img src={dataOn.flag} />  
   &nbsp;&nbsp;
   {dataOn.Name.toLocaleUpperCase()} 
   &nbsp;&nbsp;
   ( + {dataOn.phoneNumber} ) 

        </Typography>
         </ExpansionPanelDetails>
          ))}
             

          </div>

              }

        </div>
      </ExpansionPanel>
      <br/>
      <Typography style={{display: 'flex',  justifyContent:'center'}}><b style={{fontSize:16}}>Selected :</b> &nbsp; <img src={this.state.selectedItem.flag} style={{height:20,width:30,fontSize:16}}/> &nbsp; {this.state.selectedItem.Name} ( + {this.state.selectedItem.phoneNumber})</Typography>
      
       <div style={{marginTop:10,display: 'flex',  justifyContent:'center'}}>
        <Grid container >
<Grid item xs={12} sm={12}>
   <Grid container >
     <Grid item xs={12} sm={3}>
  <p style={{marginTop:22}}> + ({this.state.selectedItem.phoneNumber}) - </p>
  </Grid>
  <Grid item xs={12} sm={8}>
<TextField
         //style={{marginRight:80}}
            //variant="outlined"
              label="Enter Phone Number"
              value={this.state.phone}
              onChange={this.handlePhone}
            />
            </Grid>
            </Grid>
            </Grid>
           
         </Grid>

        </div>
        <div style={{display: 'flex',  justifyContent:'center'}}>
         <Button onClick={this.onClick}
      style={{display: 'flex',  justifyContent:'center',marginTop:18,height:30,borderRadius:5,backgroundColor:'#04af86',color:'white',fontSize:12,fontWeight:600}}
       >
        <p >Send Code</p>
       </Button> 
       </div>
      </p>

        {this.state.changeOne ?
      <p id="simple-modal-description">
      <div tyle={{display: 'flex',  justifyContent:'center'}}>
      A verification code sent to this phone number.
</div>

<div style={{marginTop:20,display: 'flex',  justifyContent:'center'}}>
  <Grid container >
<Grid item xs={12} sm={6} style={{marginLeft:10}}>
      <TextField
            //variant="outlined"
              label="Enter Verification Code"
              value={this.state.v}
              onChange={this.handle}
            />
            </Grid>
           <Grid item xs={12} sm={1}>
             </Grid>
<Grid item xs={12} sm={3}>
<Button onClick={this.laare}
      style={{marginTop:18,height:30,width:100,borderRadius:5,backgroundColor:'#04af86',color:'white',fontSize:12,fontWeight:600}}
       >
        <p>Sign In</p>
       </Button> 
      
       </Grid>
        
       </Grid>
      
</div>


      </p>
      
      : null }

      <p style={{color:'red',marginLeft:10}} id="simple-modal-description">
{this.state.error}
        </p>

      <br/><br/>
    </div>
  );
    

        return(

        

            <Container component="main" maxWidth="xs">

                <div style={{display: 'flex',  justifyContent:'center',marginTop:50}}>

   

    <Typography component="h3" variant="h5" >
          Edit Profile :
        </Typography>

        </div>

        {this.state.user.displayName != null ?

        <div style={{display: 'flex',  justifyContent:'center'}}>
            <PersonIcon style={{marginTop:35}}/>
            &nbsp;
            <TextField
            variant="outlined"
            margin="normal"
            //required
            //small
            //size="medium"
            name="user"
            placeholder={this.state.user.displayName.toUpperCase()}
            //label="email"
            //type="password"
            value={this.state.firstName}
            //id="email"
            onChange = {this.handleClickFirst}
            
          />

        </div>

        : null }

        {(this.state.user.phoneNumber != null && this.state.user.email == null) ?

        <div>

            <div style={{display: 'flex',  justifyContent:'center'}}>
        
         <p style={{marginTop:20}}><b><u>Link your Email:</u></b></p>

      </div>

      <div style={{display: 'flex',  justifyContent:'center' }}>
     
     <GoogleLogin
     clientId={clientId}
    render={renderProps => (
      <button onClick={renderProps.onClick} disabled={renderProps.disabled} style={{ width: 240, height: 40, marginTop: 20, backgroundColor: '#f96458',color:'white',fontSize:12,fontWeight:600, borderRadius: 3  }}>SIGN IN WITH GOOGLE</button>
    )}
    onSuccess={ this.login }
          onFailure={ this.handleLoginFailure }
          cookiePolicy={ 'single_host_origin' }
          responseType='code,token'
  />

 {/* <GoogleLogin
          clientId={clientId}
          buttonText='SIGN IN WITH GOOGLE'
          onSuccess={ this.login }
          onFailure={ this.handleLoginFailure }
          cookiePolicy={ 'single_host_origin' }
          responseType='code,token'
          //style={{ width: 300, height: 35, marginTop: 20, backgroundColor: '#f96458', borderRadius: 3 }}
        /> */}
         
      </div>
<br/>
       <div style={{display: 'flex',  justifyContent:'center'}}>
      {/* <Button onClick={this.onClickFacebookSignIn}
      style={{height:40,width:240,borderRadius:5,backgroundColor: 'rgb(66,93,174)',color:'white',fontSize:12,fontWeight:600}}
       >
        <FacebookIcon style={{color:'white'}} /> <p style={{marginLeft:10}}>SIGN IN WITH FACEBOOK</p>
       </Button> */}
       <FacebookLogin
  appId="449475969252254"
fields="name,email,picture"
scope="public_profile"
  autoLoad={true}
  callback={this.responseFacebook}
  render={renderProps => (
    <button onClick={renderProps.onClick} style={{height:40,width:240,borderRadius:5,backgroundColor: 'rgb(66,93,174)',color:'white',fontSize:12,fontWeight:600}}>SIGN IN WITH FACEBOOK</button>
  )}
/>
{/* <FacebookLogin
        appId="449475969252254" 
        fields="name,email,picture"
        callback={this.responseFacebook}
        icon="fa-facebook"
        //cssClass="my-facebook-button-class"
        textButton = "FACEBOOK"  
       // style={{height:40,width:240,borderRadius:5,backgroundColor: 'rgb(66,93,174)',color:'white',fontSize:12,fontWeight:600}}
      /> */}
          
  
      </div>

         <div style={{marginTop:10,display: 'flex',  justifyContent:'center'}}>
        
         <p style={{marginTop:40}}>Create New Email:</p>
         
</div>

<TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="First Name"
            name="firstName"
            //autoComplete="email"
            //autoFocus
            value={this.state.first}
            onChange = {this.handleClickfirstName}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            //autoComplete="email"
            //autoFocus
            value={this.state.lastName}
            onChange = {this.handleClicklastName}
          />
         <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            //autoComplete="email"
            //autoFocus
            value={this.state.email}
            onChange = {this.handleClickEmail}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={this.state.password}
            //autoComplete="current-password"
            onChange = {this.handleClickPwd}
          />

           <div style={{marginTop:20,display: 'flex',  justifyContent:'center'}}>
          <Button
         // style={{marginTop:20}}
            //type="submit"
            fullWidth
            variant="contained"
            style={{backgroundColor:'#04af86',color:'white'}}
            //className={classes.submit}
            onClick={this.submit_Click}
          >
            Save
          </Button>
           &nbsp;&nbsp;
           <Button
            //type="submit"
            fullWidth
            variant="contained"
            style={{backgroundColor:'#04af86',color:'white'}}
            //className={classes.submit}
            onClick={this.handleClickCancel}
          >
            Cancel
          </Button>
          </div>

          </div>

          : (this.state.user.phoneNumber == null && this.state.user.email != null) ?

          <div>

              <div style={{display: 'flex',  justifyContent:'center'}}>
        
         <p style={{marginTop:20}}><b><u>Link your Phone Number for Sign In:</u></b></p>

       
         
</div>



        <div style={{marginTop:30,display: 'flex',  justifyContent:'center'}}>
      <Button id="recaptcha-container"  onClick={this.phoneClick}
      style={{height:40,width:240,borderRadius:5,backgroundColor:'#B52A2A',color:'white',fontSize:12,fontWeight:600}}
       >
        <PhoneIcon style={{color:'white'}} /> <p style={{marginLeft:10}}>Link your Phone Number</p>
       </Button>

       <Modal
        open={this.state.setOpen}
        onClose={this.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>       
  
      </div>

       
              </div>

              : (this.state.user.phoneNumber != null && this.state.user.email != null) ?


          <div style={{marginTop:30,display: 'flex',  justifyContent:'center'}}>
 <Button
            //type="submit"
           // fullWidth
            variant="contained"
            style={{backgroundColor:'#04af86',color:'white',width:250}}
            //color="#04af86"
            //className={classes.submit}
            onClick={this.saveDisplay}
          >
            Save
          </Button>

          &nbsp;&nbsp;
           <Button
            //type="submit"
            fullWidth
            variant="contained"
            style={{backgroundColor:'#04af86',color:'white',width:250}}
            //className={classes.submit}
            onClick={this.handleClickCancel}
          >
            Cancel
          </Button>
            </div>
            
               : null }

           
        </Container>

        

        

        )

    }

}

export default EditProfile;