import React from 'react';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import Grid from '@material-ui/core/Grid';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslate, Title } from 'react-admin';
import Button from '@material-ui/core/Button';
import Modal from 'react-modal';
import * as firebase from 'firebase/app';
import 'firebase/storage';
const fetch = require('node-fetch');

const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};

class WorkOrderDetail extends React.Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      toggle: false,
      url: '',
      dataArr: [],
      target: false,
      error: '',
      scanning: false,
      showListOfTemplates: false,
      templates: [],
      //dataArr:[],
      gvFullTexts: [],
      gvDataSource: [],
      filename: '',
      image: '',
      imageAsUrl: '',
      setImageAsUrl: '',
      imageAsFile: '',
      setImageAsFile: '',
      allValue:[],
    }

  }

  async componentDidMount() {
      this.setState({loading:true })

      let arr = [];
      let allValue = [];

      let url = 'https://us-central1-imagedataextract.cloudfunctions.net/getWOData';

        //console.log('state api', this.state);

        let body = await JSON.stringify({
            "data": {
              "uid":this.props.match.params.uid,
                "woID":this.props.match.params.woID

                // "uid":"NGsbM1tmlJZrO8NYJOzi1ehPSSY2",
                // "woID":"5sDPtsNPtZ229Obl6c1X"
        }
        });
        console.log('getData body', body);
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: body,
        });
        console.log("response", response);
        const res = await response.json();
        console.log("responseJson :: ", res);
        arr.push({ value: res.result.woData.fields, key: res.result.woData.image });
        allValue.push(res.result.woData) 
        this.setState({ dataArr: arr, target: true,allValue:allValue,loading:false })

        setTimeout(() => {

          this.showFullImg(res.result.woData.imageUrl?res.result.woData.imageUrl:res.result.woData.image)

        }, 200)

    //var user = firebase.auth().currentUser;

    // firebase.auth().onAuthStateChanged(async (user) => {
    //   if (user) {
    //     console.log("user", user);
        // let arr = [];
        // let allValue = [];
        // await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(this.props.match.params.guid).doc(this.props.match.params.id).get().then(function (doc) {
        //   // querySnapshot.forEach(function (doc) {
        //     console.log("doc.data", doc.data());
        //   console.log("doc.data()", doc.data().fields);
        //   console.log("doc.data() Image", doc.data().image);
        //   arr.push({ value: doc.data().fields, key: doc.data().image });
        //    allValue.push(doc.data()) 
        //   // });
        // });

        //this.setState({ dataArr: arr, target: true,allValue:allValue,loading:false })
      // this.setState({loading:false })
        // setTimeout(() => {

        //   this.showFullImg(this.state.dataArr[0].key)

        // }, 200)


    //   } else {
    //     console.log("user not");
    //   }
    // }).bind(this);

  }


  //   componentDidMount(){

  //     let routeState;

  //     if(this.props.location.state){
  //     localStorage.setItem('routeState', JSON.stringify(this.props.location.state))
  //     routeState = this.props.location.state
  // } else {
  //     routeState = localStorage.getItem('routeState')
  //     console.log("routeState",routeState);
  // }

  //   }



  toggle(url) {
    this.setState((prevState) => ({
      toggle: !prevState.toggle
    }));

    this.setState({ url: url ,loads:false});
    //console.log("event",url);
  }

  // componentDidMount() {
  //   const { name } = this.props.location.state;
  // }

  showFullImg(filename) {
    this.setState({loads:true})
    console.log("Inside show full image ", filename);
    //this.setState({ loadingFullImg: true });
    if(filename.includes("https")){
      this.toggle(filename);
    }else{
    let fullPath = 'images/' + filename;
    let fullPathPprp = 'images_pprp/' + filename;
    const fullImgRef = firebase.storage().ref(fullPath);
    fullImgRef.getDownloadURL()
      .then((url) => {
        console.log("fullImgRef", url);
        this.toggle(url);

        //this.setState({ loadingFullImg: false, fullImageUrl: url, ModalVisibleStatus: true });
      }).catch(error => {
        console.log("Image not download", error);
        const fullImgRefPprp = firebase.storage().ref(fullPathPprp);

         fullImgRefPprp.getDownloadURL()
      .then((url) => {
        console.log("fullImgRefPprp", url);
        this.toggle(url);

        //this.setState({ loadingFullImg: false, fullImageUrl: url, ModalVisibleStatus: true });
      }).catch(error => {
        console.log("Image not download", error);
      })
      
      })
    // storage.ref('images').child(image.name).getDownloadURL()
  }
  }

  templateSelection = () => {

    let obj = {
      name:this.state.allValue[0].name,
      description:this.state.allValue[0].description,
      type:this.state.allValue[0].type,
      keywords:this.state.allValue[0].keywords,
      fields:this.state.allValue[0].fields,
      user:this.state.allValue[0].user
    }
    console.log('templateSelection', obj);

    this.props.history.push({
      pathname: '/template',
      state: {
        user:this.state.allValue[0].user,
        woId : this.props.match.params.id,
        template: obj,
        filename: this.state.allValue[0].image,
        gvDataSource: this.state.allValue[0].googleVisionAllData,
        gvFullTexts: this.state.allValue[0].googleVisionFullText
      }
    })


  }


  

  render() {
    console.log("this.state", this.state);
    console.log("data", this.state.dataArr[0], this.props.match.params.id);

    var modal = [];

    modal.push(
      <div style={{ top: 20, left: 0 }}>
        <div className="modal" style={this.state.toggle ? display : hide}>
          <div className="modal-content" style={{ display: 'flex', justifyContent: 'center' }}>
            {this.state.url ?
              <img src={this.state.url} style={{ marginTop: 20, width: 400, height: 350 }} />
              : <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
                {/* <CircularProgress color="secondary" /> */}
                <CircularProgress color="secondary" />
              </div>}
          </div>
          <div className="modal-footer">

          </div>
        </div>
      </div>
    );


    return (
        
      <div>
          {this.state.loading ?

          <div style={{display: 'flex',  justifyContent:'center',marginTop:150}}>
            {/* <CircularProgress color="secondary" /> */}
            <CircularProgress color="secondary" />
            </div>
           
            :
<div>
        {this.state.dataArr[0] ?
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6}>
              <p style={{ float: 'right' }}>Document Details:</p>
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <Button style={{ float: 'right', backgroundColor: '#04af86', width: '24%', marginTop: 10 }} 
              variant="contained" color="primary" onClick={this.templateSelection} >
                Edit
      </Button> */}
            </Grid>
          </Grid>
          : null}
          <br/>

          <Grid container spacing={2}>

            <Grid item xs={12} sm={8}>

              {this.state.loads ?

          <div style={{display: 'flex',  justifyContent:'center',marginTop:150}}>
            {/* <CircularProgress color="secondary" /> */}
            <CircularProgress color="secondary" />
            </div>
           
            :
        <div style={{padding:10}}>
          {/* <p style={{textAlign:'center'}}>Image</p> */}
        <div className="modal" style={this.state.toggle ? display : hide}>
          <div className="modal-content" style={{ border: '1px solid #bdb0b0',height:window.innerHeight + 'px' ,overflowY:'scroll' }}>
            {this.state.url ?
              <img src={this.state.url} style={{ width:'100%'}} />
              : <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
                {/* <CircularProgress color="secondary" /> */}
                <CircularProgress color="secondary" />
              </div>}
          </div>
          <div className="modal-footer">

          </div>
        </div>
      </div>
      }
        
        </Grid>
        <Grid item xs={12} sm={4}>
<div style={{marginTop:10,height:window.innerHeight + 'px',overflowY:'scroll'}}>
<Table size="small" style={{height: '100%'}}>
          {this.state.dataArr[0] ?
            <TableBody>
              {this.state.dataArr[0].value.map(dataTwo => (
                <TableRow>
                  <TableCell>

                   <b> {dataTwo.name}</b> : {dataTwo.value}

                  </TableCell>

                </TableRow>
              ))}
            </TableBody>

            : null}

        </Table>
        </div>
      
        </Grid>
        </Grid>
        </div>
         }
      
</div>
 

    )

  }

}

export default WorkOrderDetail;