import React from 'react';
import { Route } from 'react-router-dom';
//import Configuration from './configuration/Configuration';
//import Segments from './segments/Segments';
import ImageDataExtract from './ImageDataExtract/ImageDataExtract';
import WorkOrderList from './workOrderList/workOrderList';
import WorkOrderDetails from './workOrderList/WorkOrderDetails';
import Profile from './profile/profile';
import Login from './layout/Login';
import SignUp from './layout/SignUp';
import EditProfile from './profile/EditProfile';
import ChangePassword from './profile/ChangePassword';
import TemplateInfo from './template/template';
import Admin from './admin/admin';
import EditTemplate from './template/editTemplate';
import TemplateList from './template/templateList';
import WorkOrderDetail from './workOrderList/workOrderDetail';

export default [
    // <Route exact path="/configuration" render={() => <Configuration />} />,
    // <Route exact path="/segments" render={() => <Segments />} />,
    <Route exact path="/ImageDataExtract" render={() => <ImageDataExtract />} component={ImageDataExtract} />,
    <Route exact path="/WorkOrderList" render={() => <WorkOrderList />} component={WorkOrderList} />,
    <Route exact path="/WorkOrderList/details/:id" render={() => <WorkOrderDetails />} component={WorkOrderDetails} />,
    <Route exact path="/Login" render={() => <Login />} component={Login} />,
    <Route exact path="/SignUp" render={() => <SignUp />} component={SignUp} noLayout />,
    <Route exact path="/Profile" render={() => <Profile />} component={Profile} />,
    <Route exact path="/Profile/edit" render={() => <EditProfile />} component={EditProfile} />,
    <Route exact path="/changePassword" render={() => <ChangePassword />} component={ChangePassword} />,
    <Route exact path="/template" render={() => <TemplateInfo />} component={TemplateInfo} />,
    <Route exact path="/Admin" render={() => <Admin />} component={Admin} />,
    <Route exact path="/editTemplate" render={() => <EditTemplate />} component={EditTemplate} />,
    <Route exact path="/templateList" render={() => <TemplateList />} component={TemplateList} />,
    <Route exact path="/WorkOrderList/detail/:woID/:uid" render={() => <WorkOrderDetail />} component={WorkOrderDetail} />,
];
