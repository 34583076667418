import React from 'react';
import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
//import 'firebase/functions';
//import firebase from 'firebase/app';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
const fetch = require('node-fetch');



class ImageDataExtract extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      error: '',
      scanning: false,
      showListOfTemplates: false,
      templates: [],
      dataArr: [],
      gvFullTexts: [],
      gvDataSource: [],
      filename: '',
      image: '',
      imageAsUrl: '',
      setImageAsUrl: '',
      imageAsFile: '',
      setImageAsFile: '',
      pdf: false,
    }
  }

  componentWillMount= async () =>{
    if (this.props.location.state) {
      console.log("this.props", this.props.location.state.name);
      this.setState({ prop: this.props.location.state.name.key });
    }

  }

  componentDidMount() {

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        console.log("user id", user.uid);

        let arr = [];
        await firebase.firestore().collection("template").doc("tempItem").collection(user.uid).get().then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {

            console.log("doc", doc.data());
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, &quot; =&gt; &quot;, doc.data());
            arr.push(doc.data())
          });
        });
        // console.log(&quot;arr.push&quot;, arr)
        this.setState({ dataArr: arr, target: true })

        console.log("dataArr", this.state.dataArr);


      }
    })


  }



  templateSelection = (item) => {
    console.log("template selected ::",item)
    let stateObj= {
      pdf:this.state.pdf,
      user: firebase.auth().currentUser.uid,
      template: item,
      filename: this.state.imageName,
      gvDataSource: this.state.gvDataSource,
      gvFullTexts: this.state.gvFullTexts
    }
    localStorage.setItem('tempstate',JSON.stringify(stateObj))

    this.props.history.push({
      pathname: '/template',
      state: stateObj
    })
  }

  detectScannedDocData = () => {
    const items = this.state.gvFullTexts.filter(item => item.value.toLowerCase().includes('inspect'));
    if (items.length > 0 && this.state.dataArr.length > 0) {
      this.templateSelection(this.state.dataArr[0]);
    } else {
      if (this.state.templates.length > 1) {

        if (this.state.prop) {

          this.templateSelection(this.props.location.state.name.value);

        } else {

          console.log("unable to identify document");
          this.setState({ showListOfTemplates: true });

        }

      } else {
        console.log('state template', this.state);
        this.templateSelection(this.state.templates[0]);
      }
    }
  }


  apicall = async (event) => {
    console.log("event", event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].type.includes("pdf")) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({ file: e.target.result, image: e.target.result, pdf: true, scanning: true });

        };
        reader.readAsDataURL(event.target.files[0]);

        const file = event.target.files[0];
        // this.setState({imageAsFile:file});
        var path = (window.URL || window.webkitURL).createObjectURL(file);
        console.log('PDF state apicall', path);
        const storage = firebase.storage();

        if (file === '') {
          console.log("Not an file")
        }

        const uploadTask = storage.ref(`/pdfs/${file.name}`).put(file)
        console.log("uploadTask", uploadTask);

        uploadTask.on('state_changed',
          (snapShot) => {
            //takes a snap shot of the process as it is happening
            console.log(snapShot)
          }, (err) => {
            //catches the errors
            console.log(err)
          }, async () => {
            let url = 'https://us-central1-imagedataextract.cloudfunctions.net/getImageDataWithGC-PDF';

            console.log('state api', this.state);

            let body = await JSON.stringify({
              "data": {
                type:"pdf",
                file: file.name
              }
            });
            console.log('getImageData body', body);
            const response = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: body,
            });
            console.log("response", response);
            const res = await response.json();
            console.log("responseJson :: ", res);
            localStorage.setItem('imageName',file.name)
            this.setState({ imageName: file.name });

            if (res.result && res.result.error) {
              this.setState({ error: 'There is some error in scanning the document', scanning: false });
  
            } else if (res.result) {
              this.setState({ templates: res.result.templates, gvFullTexts: res.result.fullTexts, gvDataSource: res.result.imageData.responses[0], scanning: false });
              { this.detectScannedDocData() }
            } else {
              this.setState({ error: 'There is some error in scanning the document', scanning: false });
            }
            //var urlFire = fireBaseUrl;
            console.log('state firebaseUrl', this.state);
            // })
          })
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({ image: e.target.result, scanning: true });

        };
        reader.readAsDataURL(event.target.files[0]);
        const image = event.target.files[0];
        // this.setState({imageAsFile:image});
        console.log('state apicall', this.state);

        const storage = firebase.storage();

        if (image === '') {
          console.log("Not an image")
        }

        const uploadTask = storage.ref(`/images/${image.name}`).put(image)
        console.log("uploadTask", uploadTask);

        uploadTask.on('state_changed',
          (snapShot) => {
            //takes a snap shot of the process as it is happening
            console.log(snapShot)
          }, (err) => {
            //catches the errors
            console.log(err)
          }, () => {
            // gets the functions from storage refences the image storage in firebase by the children
            // gets the download url then sets the image from firebase as the value for the imgUrl key:
            storage.ref('images').child(image.name).getDownloadURL()
              .then(async (fireBaseUrl) => {
                let url = 'https://us-central1-imagedataextract.cloudfunctions.net/getImageDataWithGC-NEW';

                let body = await JSON.stringify({
                  "data": {
                    type:"image",
                    image: fireBaseUrl
                  }
                });
                console.log('getImageData body', body);
                const response = await fetch(url, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: body,
                });
                console.log("response", response);
                const res = await response.json();
                console.log("responseJson :: ", res);
                localStorage.setItem('imageName',image.name)
                this.setState({ imageName: image.name });

                if (res.result && res.result.error) {
                  this.setState({ error: 'There is some error in scanning the document', scanning: false });

                } else if (res.result) {
                  this.setState({ templates: res.result.templates, gvFullTexts: res.result.fullTexts, gvDataSource: res.result.imageData.responses[0], scanning: false });
                  { this.detectScannedDocData() }
                } else {
                  this.setState({ error: 'There is some error in scanning the document', scanning: false });
                }
                //var urlFire = fireBaseUrl;
                console.log('state firebaseUrl', this.state);
              })
          })

        // console.log("fireApp auth",firebase.auth().currentUser);
      }

    }

  }

  render() {

    return (

      <div>
        <div style={{ backgroundImage: `url(${require("../layout/app_bg.jpg")})`, position: 'fixed', zIndex: 0, top: 0, left: 0, right: 0, bottom: 0, marginLeft: 57 }}> </div>
        <Grid container>
          <Grid item xs={5}>
            <div style={{
              backgroundImage: `url(${require("../layout/dg_white.png")})`,
              width: '100%',
              position: 'relative',
              backgroundRepeat: 'no-repeat',
              paddingTop: '96%',
              backgroundSize: '330px',
              backgroundPosition: 'center right'
            }}></div>
            {/* <img src= "../images/dg_white.png"/> */}
          </Grid>
          <Grid item xs={7}>
            <Container component="main" maxWidth="xs">
              <div style={{ backgroundColor: 'white', position: 'relative', marginTop: 90, marginLeft: 30, marginRight: 30, borderRadius: 30, width: '115%' }}>
                <Card style={{ display: 'flex', justifyContent: 'center', borderRadius: 30 }}>
                  <CardContent style={{ width: "100%", marginBottom: -30 }} >
                  {!this.state.scanning ? <div style={{
                      height: 200, borderStyle: 'dashed', borderWidth: 5,
                      borderColor: '#ddd', marginTop: 20,marginBottom: 40,
                      borderRadius: 20, position: 'relative'
                    }}>
                      <div style={{ textAlign: 'center' }}>
                        <br />
                        <br />
                        <p style={{ fontSize: 20, fontFamily: 'Rockwell' }}>Drop an image here</p>
                        <p style={{ fontFamily: 'Times New Roman' }}>or Click to choose.</p>
                        <input accept="image/*,.pdf" type="file" id="imageUpload" style={{ width: "100%", height: 200, position: 'absolute', top: 0, left: 0, textIndent: -9999, cursor: 'pointer' }} onChange={this.apicall} />
                      </div>
                    </div>
                    :
                    <p style={{ textAlign: 'center' }}>Please Wait Scanning Image...</p>}
                    {this.state.image ? <div className="modal" style={{ display: 'block', justifyContent: 'center' }}>
                      <div className="modal-content" style={{ border: '1px solid #bdb0b0', height: window.innerHeight + 'px', overflowY: 'scroll' }}>
                        {this.state.pdf ? <iframe src={this.state.image} width="100%" height="450"></iframe> :
                          <img src={this.state.image} style={{ width: '100%' }} />}
                      </div>
                    </div>
                      : null
                    }
                    {this.state.error && this.state.error.length > 0 ? <p style={{ textAlign: 'center' }}>{this.state.error}</p> : null}
                    {this.state.showListOfTemplates && <p>
                      <p style={{ textAlign: 'center' }}>Unable to identify the type of scanned document image. Please select type from below list.</p>
                      {this.state.templates.length == 0 ?
                        <p>No templates found</p>
                        :
                        <div style={{ overflow: 'scroll' }}>

                          <List>
                            <ListItem alignItems="flex-start" style={{ display: 'flex', justifyContent: 'center', }} >
                              {this.state.templates.map(dataOne => (
                                <div style={{ borderStyle: 'solid', borderColor: '#04af86', borderRadius: 10, backgroundColor: '#04af86', color: 'white', display: 'block', marginRight: 6, paddingLeft: 8, paddingRight: 8, cursor: 'pointer' }}>
                                  <ListItemText
                                    onClick={() => this.templateSelection(dataOne)}
                                    style={{ textAlign: 'center' }}
                                    primary={dataOne.name}
                                    secondary={
                                      <React.Fragment>
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          //className={classes.inline}
                                          //color="textPrimary"
                                          style={{ color: 'white' }}
                                        >
                                          {dataOne.description}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                </div>
                              ))}
                              {this.state.dataArr.map(dat => (
                                <div style={{ borderStyle: 'solid', borderColor: '#04af86', borderRadius: 10, backgroundColor: '#04af86', color: 'white', display: 'block', marginRight: 6, paddingLeft: 8, paddingRight: 8, cursor: 'pointer' }}>
                                  <ListItemText
                                    onClick={() => this.templateSelection(dat)}
                                    style={{ textAlign: 'center' }}
                                    primary={dat.name}
                                    secondary={
                                      <React.Fragment>
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          //className={classes.inline}
                                          //color="textPrimary"
                                          style={{ color: 'white' }}
                                        >
                                          {dat.description}
                                        </Typography>

                                      </React.Fragment>
                                    }
                                  />
                                </div>
                              ))}
                            </ListItem>
                          </List>
                        </div>
                      }
                    </p>
                    }
                  </CardContent>
                </Card>
              </div>
            </Container>
          </Grid>
        </Grid>
      </div>

    )


  }



}

export default ImageDataExtract;