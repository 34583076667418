import React, { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Logo from './Logo';



const NOCustomAppBar = (props: any) => {
    //const classes = useStyles();
    return (

        <div style={{backgroundColor:'rgb(247, 148, 3)',marginTop:-40}}>
             <Grid container spacing={2}>

            <Grid item xs={12} sm={5}>
            <img style={{padding:10}} src="https://www.digitalglyde.com/wp-content/uploads/2019/12/logo2-3.png"/>
            </Grid>
            <Grid item xs={12} sm={6}>
            <p style={{textAlign:'left',color:'white',padding:6}}><b>Image Data Extract</b></p>
            </Grid>
            </Grid>
            </div>


        // <AppBar {...props} userMenu={<CustomUserMenu />} style={{backgroundColor:'#04af86',color:'white'}}>
        //     <Typography
        //         variant="h6"
        //         color="inherit"
        //         className={classes.title}
        //         id="react-admin-title"
        //     />
        //    Image Data Extract LL
        //     <span className={classes.spacer} />
        // </AppBar>
    );
};

export default NOCustomAppBar;
