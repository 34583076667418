import React from 'react';
import Card from '@material-ui/core/Card';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Table from '@material-ui/core/Table';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Link } from 'react-router-dom';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'underscore';
import Switch from '@material-ui/core/Switch';
// import { DataGrid } from '@material-ui/data-grid';
//import LinkToRelatedCustomers from './LinkToRelatedCustomers';
//import segments from './data';
import moment from 'moment';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';

import * as firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/functions';
import 'firebase/database';
const fetch = require('node-fetch');

const columns = [
    { field: 'invoiceNum', headerName: <b>Invoice Number</b>, width: 300 },
    //{ field: 'firstName', headerName: 'Vendor Name', width: 200 },

    { field: 'fields', headerName: <b>Vendor Name</b>, width: 300 },
    { field: 'createdOn', headerName: <b>Date</b>, width: 300 },
    {
        field: 'doc_Validation',
        headerName: <b>Validated</b>,
        //type: 'number',
        width: 300,
    },
    //   {
    //     field: 'fullName',
    //     headerName: 'Full name',
    //     //description: 'This column has a value getter and is not sortable.',
    //     //sortable: false,
    //     width: 200,
    //     valueGetter: (params) =>
    //      `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
    //   },
];

const rows = [
    { id: 1, invoiceNum: '12454512', createdOn: moment("2020-11-25 14:39:46.054449").format('MMM DD, YYYY'), doc_Validation: 'Yes' },

];

class WorkOrderList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataArr: [],
            searchText: '',
            target: false,
            checkedB: false,
            tablePage: 0,
            envCount: 0,
            rowsPerPage: 10,
            envLimit: 10,
            compFirst: 0,
            compLast: 0,
            userDocFirst: 0,
            userDocLast: 0,
            setPage: 0,
        }
    }

    componentDidMount() {
        // this.searchText()
        var user = firebase.auth().currentUser;
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                let arr = [];
                let companyId = "";
                let userId = await firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).get();
                if (userId.data().companyId) {
                    companyId = userId.data().companyId;

                    // Get the last document
                    let querySnapshot = await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(companyId).orderBy('createdOn', 'desc').limit(50).get()
                    if (querySnapshot.docs.length > 0) {

                        const last = querySnapshot.docs[querySnapshot.docs.length - 1];
                        this.setState({ compLast: last, compFirst: querySnapshot.docs[0], setPage: this.state.setPage + 1 })
                        // .then(function (querySnapshot) {
                        querySnapshot.forEach(async (doc) => {
                            let newObject = doc.data();
                            let invoiceNum = "N/A"
                            newObject.key = doc.id;
                            await newObject.fields.map(dat => {
                                if (dat.name.toLowerCase().includes("invoice number") || dat.searchRef.toUpperCase() === "INVOICE_NUMBER") {
                                    // console.log("newObject.fields name", dat.name);
                                    invoiceNum = dat.value ? dat.value : "N/A"
                                }
                            })
                            // console.log("invoiceNum", invoiceNum);
                            newObject.invoiceNum = invoiceNum
                            arr.push(newObject);
                        });
                    }
                    // });
                }

                let userSnapshot = await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(user.uid).orderBy('createdOn', 'desc').limit(50).get()
                if (userSnapshot.docs.length > 0) {

                    const userDocLast = userSnapshot.docs[userSnapshot.docs.length - 1];
                    this.setState({ userDocLast, userDocFirst: userSnapshot.docs[0], setPage: this.state.setPage + 1 })

                    //    .then(function (querySnapshot) {
                    userSnapshot.forEach(async (doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        //console.log(doc.id, &quot; =&gt; &quot;, doc.data());
                        let newObject = doc.data();
                        let invoiceNum = "N/A"
                        newObject.key = doc.id;
                        await newObject.fields.map(dat => {
                            if (dat.name.toLowerCase().includes("invoice number") || dat.searchRef.toUpperCase() === "INVOICE_NUMBER") {
                                // console.log("newObject.fields name", dat.name);
                                invoiceNum = dat.value ? dat.value : "N/A"
                            }
                        })
                        newObject.invoiceNum = invoiceNum
                        // console.log("invoiceNum", invoiceNum);
                        arr.push(newObject);
                    });
                }
                // });
                // console.log(&quot;arr.push&quot;, arr)
                console.log("arr cp", arr);
                setTimeout(() => {
                    this.setState({ dataArr: arr, target: true })
                }, 3000);
            } else {
                console.log("user not");
            }
        }).bind(this);
    }
    getDataFromDb = (buttonType) => {
        this.setState({ target: false })
        var user = firebase.auth().currentUser;
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                let arr = [];
                let companyId = "";
                let userSnapshot = null
                let userId = await firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).get();
                if (userId.data().companyId) {
                    companyId = userId.data().companyId;
                    let querySnapshot = null
                    // Get the last document
                    if (buttonType == "previous") {
                        querySnapshot = await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(companyId).orderBy('createdOn', 'desc').endBefore(this.state.compFirst).limitToLast(50).get()
                    } else if (buttonType == "next") {
                        querySnapshot = await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(companyId).orderBy('createdOn', 'desc').startAfter(this.state.compLast).limit(50).get()
                    } else if (buttonType == "last") {
                        querySnapshot = await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(companyId).orderBy('createdOn', 'asc').limit(50).get()
                    } else if (buttonType == "first") {
                        querySnapshot = await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(companyId).orderBy('createdOn', 'desc').limit(50).get()
                    }
                    if (querySnapshot.docs.length > 0) {

                        const last = querySnapshot.docs[querySnapshot.docs.length - 1];
                        this.setState({ compLast: last, compFirst: querySnapshot.docs[0] })
                        querySnapshot.forEach(async (doc) => {
                            let newObject = doc.data();
                            let invoiceNum = "N/A"
                            newObject.key = doc.id;
                            await newObject.fields.map(dat => {
                                if (dat.name.toLowerCase().includes("invoice number") || dat.searchRef.toUpperCase() === "INVOICE_NUMBER") {
                                    // console.log("newObject.fields name", dat.name);
                                    invoiceNum = dat.value ? dat.value : "N/A"
                                }
                            })
                            // console.log("invoiceNum", invoiceNum);
                            newObject.invoiceNum = invoiceNum
                            arr.push(newObject);
                        });
                    }
                }
                if (buttonType == "previous") {
                    userSnapshot = await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(user.uid).orderBy('createdOn', 'desc').endBefore(this.state.userDocFirst).limitToLast(50).get()
                } else if (buttonType == "next") {
                    userSnapshot = await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(user.uid).orderBy('createdOn', 'desc').startAfter(this.state.userDocLast).limit(50).get()
                } else if (buttonType == "last") {
                    userSnapshot = await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(user.uid).orderBy('createdOn', 'asc').limit(50).get()
                } else if (buttonType == "first") {
                    userSnapshot = await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(user.uid).orderBy('createdOn', 'desc').limit(50).get()
                }
                console.log("Button type",buttonType,userSnapshot)

                if (userSnapshot.docs.length > 0) {

                    const userDocLast = userSnapshot.docs[userSnapshot.docs.length - 1];
                    this.setState({ userDocLast, userDocFirst: userSnapshot.docs[0] })
                    // .then(function (querySnapshot) {
                    userSnapshot.forEach(async (doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        let newObject = doc.data();
                        let invoiceNum = "N/A"
                        newObject.key = doc.id;
                        await newObject.fields.map(dat => {
                            if (dat.name.toLowerCase().includes("invoice number") || dat.searchRef.toUpperCase() === "INVOICE_NUMBER") {
                                // console.log("newObject.fields name", dat.name);
                                invoiceNum = dat.value ? dat.value : "N/A"
                            }
                        })
                        newObject.invoiceNum = invoiceNum
                        console.log("doc.id before insert :: ",doc.id, doc.data());
                        // console.log("invoiceNum", invoiceNum);
                        arr.push(newObject);
                    });
                }
                // });
                // console.log(&quot;arr.push&quot;, arr)
                console.log("arr cp", arr);
                this.setState({ dataArr: arr, target: true })
                setTimeout(() => {
                    console.log("arr cp last ::", arr);
                    this.setState({ dataArr: arr, target: true })
                }, 2000);
            } else {
                console.log("user not");
            }
        }).bind(this);
    }
    handleChange = () => {
        console.log("handleChange")
        this.setState({ checkedB: !this.state.checkedB })

    }
    handleChangePage = (event, newPage) => {
        console.log("handleChangePage ", newPage)
        let envSkip = (newPage) * this.state.rowsPerPage;
        let envLimit = this.state.rowsPerPage;
        this.setState({ tablePage: newPage, envLimit, envSkip }, () => this.getDataFromDb());
    };

    handleChangeRowsPerPage = (event) => {
        let page = parseInt(event.target.value, 10);
        console.log('handleChangeRowsPerPage', page)
        let envLimit = page;
        let envSkip = 0
        this.setState({ tablePage: 0, rowsPerPage: page, envLimit, envSkip }, () => this.getDataFromDb())
    };

    handleStatusChange = (event) => {
        this.setState({ status: event.target.value }, () => this.getDataFromDb())
    };

    handleTypeChange = (event) => {
        this.setState({ type: event.target.value }, () => this.getDataFromDb())
    };
    searchPage = async () => {
        this.setState({ target: false })
        if (!this.state.setPage || this.state.setPage.length < 1) {
            console.log("componentDidMount")

            this.componentDidMount();
        } else {
            let arr = [];
            let companyId = "";
            let userId = await firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).get();
            if (userId.data().companyId) {
                companyId = userId.data().companyId;

                // Get the last document
                // let snapshot = await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(companyId).where("incrNum", ">=", (Number(this.state.setPage)-1) * 50).where("incrNum", "<=", (Number(this.state.setPage) * 50) + 50).get()
                let snapshot = await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(companyId).where("incrNum", ">=", ((Number(this.state.setPage) - 1) * 50) + 1).limit(50).get()
                if (snapshot.docs.length > 0) {
                    const last = snapshot.docs[snapshot.docs.length - 1];
                    this.setState({ compLast: last, compFirst: snapshot.docs[0] })
                    snapshot.forEach(async querySnapshot => {
                        if (querySnapshot.data()) {
                            let newObject = querySnapshot.data();
                            let invoiceNum = "N/A"
                            newObject.key = querySnapshot.id;
                            await newObject.fields.map(dat => {
                                if (dat.name.toLowerCase().includes("invoice number") || dat.searchRef.toUpperCase() === "INVOICE_NUMBER") {
                                    invoiceNum = dat.value ? dat.value : "N/A"
                                }
                            })
                            newObject.invoiceNum = invoiceNum

                            arr.push(newObject);
                        }
                    });
                }
            }
            let userSnapshot = await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(firebase.auth().currentUser.uid).where("incrNum", ">=", ((Number(this.state.setPage) - 1) * 50) + 1).limit(50).get()
            if (userSnapshot.docs.length > 0) {
                const userDocLast = userSnapshot.docs[userSnapshot.docs.length - 1];
                this.setState({ userDocLast, userDocFirst: userSnapshot.docs[0] })
                userSnapshot.forEach(async querySnapshot => {
                    if (querySnapshot.data()) {
                        let newObject = querySnapshot.data();
                        let invoiceNum = "N/A"
                        newObject.key = querySnapshot.id;
                        await newObject.fields.map(dat => {
                            if (dat.name.toLowerCase().includes("invoice number") || dat.searchRef.toUpperCase() === "INVOICE_NUMBER") {
                                invoiceNum = dat.value ? dat.value : "N/A"
                            }
                        })
                        newObject.invoiceNum = invoiceNum

                        arr.push(newObject);
                    }
                });
            }
            console.log("arr cp", arr);
            this.setState({ dataArr: arr, target: true })
        }
    }
    searchIDE = async () => {
        this.setState({ target: false })
        if (!this.state.searchText || this.state.searchText.length < 2) {
            console.log("componentDidMount")

            this.componentDidMount();
        } else {
            let arr = [];
            let companyId = "";
            let userId = await firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).get();
            if (userId.data().companyId) {
                companyId = userId.data().companyId;

                // Get the last document
                let querySnapshot = await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(companyId).doc(this.state.searchText).get()
                console.log("querySnapshot data", querySnapshot.data())
                if (querySnapshot.data()) {
                    let newObject = querySnapshot.data();
                    let invoiceNum = "N/A"
                    newObject.key = querySnapshot.id;
                    await newObject.fields.map(dat => {
                        if (dat.name.toLowerCase().includes("invoice number") || dat.searchRef.toUpperCase() === "INVOICE_NUMBER") {
                            invoiceNum = dat.value ? dat.value : "N/A"
                        }
                    })
                    newObject.invoiceNum = invoiceNum

                    arr.push(newObject);
                }
            }
            let userSnapshot = await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(firebase.auth().currentUser.uid).doc(this.state.searchText).get()
            console.log("querySnapshot data", userSnapshot.data())
            if (userSnapshot.data()) {
                let newObject = userSnapshot.data();
                let invoiceNum = "N/A"
                newObject.key = userSnapshot.id;
                await newObject.fields.map(dat => {
                    if (dat.name.toLowerCase().includes("invoice number") || dat.searchRef.toUpperCase() === "INVOICE_NUMBER") {
                        invoiceNum = dat.value ? dat.value : "N/A"
                    }
                })
                newObject.invoiceNum = invoiceNum
                arr.push(newObject);
            }
            console.log("arr cp", arr);
            this.setState({ dataArr: arr, target: true })
        }
    }

    nextPage = () => {

        //this.props.history.push('/WorkOrderList/details/' + dataOne.key)

    }

    render() {

        var newArr = [];
        var dataArray = [];
        dataArray = this.state.dataArr;
        //let i =1;
        _.each(dataArray, (dat) => {
            if (dat.doc_Validation) {
                newArr.push({ id: dat.key, invoiceNum: dat.invoiceNum, fields: dat.fields[0].value, createdOn: moment(dat.createdOn).format('MMM DD, YYYY'), doc_Validation: 'Yes' })
            } else {
                newArr.push({ id: dat.key, invoiceNum: dat.invoiceNum, fields: dat.fields[0].value, createdOn: moment(dat.createdOn).format('MMM DD, YYYY'), doc_Validation: 'No' })
            }
        })
        // if(this.state.searchText.length>0){
        //     dataArray = this.state.dataArr.filter(searchData=>searchData.image.includes(this.state.searchText))
        // }
        //dataArray = newArr;

        if (this.state.checkedB && this.state.dataArr.length > 0) {
            dataArray = this.state.dataArr.filter(dat => !dat.doc_Validation)
            console.log("dataArray", dataArray)
            //dataArray = newArr
        }
        console.log("this.state ::::", this.state)
        return (

            <div>
                {this.state.target ?
                    this.state.dataArr.length > 0 ?
                    
                        <div>
                            <Card>
                            <Grid container spacing={2}>
                <Grid item sm={6}>
                <img src= {require("./dg_full_resolution.png")} style={{width:'13%',padding:10}}/>
                  <p style={{ float: 'right',fontWeight:600,fontSize:18}}>Document List:</p>
                </Grid>
                <Grid item sm={6}>
                                <div style={{ float: 'right', marginTop: 10 }}>
                                    <TextField
                                        label="Search Document"
                                        size="small"
                                        style={{borderRadius:10,width:'50%',paddingRight:15}}
                                        floatinglabelstyle={{ color: "#0091AE" }}
                                        variant="outlined"
                                        //style={{ padding: 3, width: 280, color: "#0091AE", height: 51 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment>
                                                    <SearchIcon onClick={() => {
                                            this.searchIDE()
                                        }} 
                                        style={{ color: "rgb(194 194 194)" ,cursor:'pointer'}} />
                                                </InputAdornment>
                                            )
                                        }}
                                        // onKeyDown={()=>this.searchIDE()}
                                        onChange={(e) => this.setState({ searchText: e.target.value })}
                                        value={this.state.searchText}
                                    />
                                    {/* <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            this.searchIDE()
                                        }}
                                        style={{ height: 35,width:'16%', fontSize:13,backgroundColor: '#5cb85c',borderRadius:10 }}
                                    >
                                        Search
                                     </Button> */}
                                  &nbsp;&nbsp;
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                style={{ float: 'right'}}
                                                checked={this.state.checkedB}
                                                onChange={this.handleChange}
                                                color="primary"
                                                name="checkedB"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        }
                                        label={this.state.checkedB ? <span style={{color:'#afadad',fontSize:12,display: 'inline-block', width:'130px'}}>Show all Records</span> : <span style={{color:'#afadad',fontSize:12, display: 'inline-block', width:'130px'}}>Hide Validated Records</span>}
                                    /> 
                                </div>
                                </Grid>
                                </Grid>
                                <br /><br />
                                {/* <div style={{ height: 600, width: '100%', marginTop: 30 }}>
                                    <DataGrid rows={newArr} columns={columns} pageSize={10} hideFooter={true} onRowClick={this.nextPage} />
                                </div> */}
                                <Table>
                                    <TableBody>
                                        {_.sortBy(dataArray, 'createdOn').reverse().map(dataOne => (
                                            <TableRow key={dataOne.key}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12}>
                                                        <Link style={{ textDecoration: 'none' }} to={
                                                            {
                                                                pathname: "/WorkOrderList/details/" + dataOne.key,
                                                                //state : {name : dataOne.value}
                                                            }
                                                        }>
                                                            <TableCell style={{ borderBottom: "none", fontSize: 16 }}>
                                                                <b style={{color:'#0f5aa9'}}> {dataOne.templateName ? dataOne.templateName : dataOne.name}  {dataOne.invoiceNum}</b><br />
                                                                {dataOne.fields[0].name} : {dataOne.fields[0].value}<br />
                                                                Date : {dataOne.createdOn ? moment(dataOne.createdOn).format("MMM DD, YYYY") : 'Not Provided'}<br />
                                                                Image : {dataOne.image ? dataOne.image: 'Not Provided'}
                                                                {dataOne.doc_Validation ?
                                                                    <div>
                                                                        <DoneOutlineIcon style={{ color: 'green', fontSize: 17 }} /> <b style={{ color: '#387339' }}>Validated</b>
                                                                    </div>
                                                                    : null}
                                                            </TableCell>
                                                          
                                                        </Link>
                                                        <hr/>
                                                    </Grid>
                                                </Grid>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Card>
                            <div style={{ padding: 20 }}>
                                <Grid container spacing={0}>
                                    {/* <Grid item xs={2} sm={2}>
                                    </Grid> */}
                                    <Grid item xs={2} >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.setState({ setPage: 1 }, () => this.getDataFromDb("first"))
                                            }}
                                            style={{ height: 30, color: "#0091AE", background: "#F5F8FA" }}
                                        >
                                            First
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.setState({ setPage: this.state.setPage - 1 }, () => this.getDataFromDb("previous"))
                                            }}
                                            style={{ height: 30, color: "#0091AE", background: "#F5F8FA" }}
                                        >
                                            Previous
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.setState({ setPage: this.state.setPage + 1 }, () => this.getDataFromDb("next"))
                                            }}
                                            style={{ height: 30, color: "#0091AE", background: "#F5F8FA" }}
                                        >
                                            Next
                                        </Button>
                                    </Grid>

                                    <Grid item xs={2}>
                                    </Grid>
                                    <Grid item >
                                        <p style={{ textAlign: 'center', float: "left", marginTop: 10 }}>Page No:-  </p>
                                    &nbsp;&nbsp;
                                    <TextField
                                            // label="Search"
                                            size="small"
                                            style={{ width: 50 }}
                                            floatinglabelstyle={{ color: "#0091AE" }}
                                            variant="outlined"
                                            onChange={(e) => this.setState({ setPage: Number(e.target.value) })}
                                            value={this.state.setPage}
                                        />

                                       &nbsp;&nbsp;
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.searchPage()
                                            }}
                                            style={{ height: 30, color: "#0091AE", background: "#F5F8FA" }}
                                        >
                                            Search
                                     </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        
                        : <div> <p style={{ textAlign: 'center', marginTop: 20 }}>No record Found!</p>
                            <div style={{ padding: 20 }}>
                                <Grid container spacing={0}>
                                    {/* <Grid item xs={2} sm={2}>
                                    </Grid> */}
                                    <Grid item xs={2} >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.setState({ setPage: 1 }, () => this.getDataFromDb("first"))
                                            }}
                                            style={{ height: 30, color: "#0091AE", background: "#F5F8FA" }}
                                        >
                                            First
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.setState({ setPage: this.state.setPage - 1 }, () => this.getDataFromDb("previous"))
                                            }}
                                            style={{ height: 30, color: "#0091AE", background: "#F5F8FA" }}
                                        >
                                            Previous
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.setState({ setPage: this.state.setPage + 1 }, () => this.getDataFromDb("next"))
                                            }}
                                            style={{ height: 30, color: "#0091AE", background: "#F5F8FA" }}
                                        >
                                            Next
                                        </Button>
                                    </Grid>

                                    <Grid item xs={2}>
                                    </Grid>
                                    <Grid item >
                                        <p style={{ textAlign: 'center', float: "left", marginTop: 10 }}>Page No:-  </p>
                                    &nbsp;&nbsp;
                                    <TextField
                                            // label="Search"
                                            size="small"
                                            style={{ width: 50 }}
                                            floatinglabelstyle={{ color: "#0091AE" }}
                                            variant="outlined"
                                            onChange={(e) => this.setState({ setPage: Number(e.target.value) })}
                                            value={this.state.setPage}
                                        />

                                       &nbsp;&nbsp;
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.searchPage()
                                            }}
                                            style={{ height: 30, color: "#0091AE", background: "#F5F8FA" }}
                                        >
                                            Search
                                     </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    :
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 150 }}>
                        {/* <CircularProgress color="secondary" /> */}
                        <CircularProgress color="secondary" />
                    </div>
                }

            </div>
        )
    }
}

export default WorkOrderList;