import React from 'react';
import { useSelector } from 'react-redux';
import { Layout, Sidebar } from 'react-admin';
import NOCustomAppBar from './NoAppBar';
import NoMenu from './NoMenu';
import { darkTheme, lightTheme } from './themes';
import { AppState } from '../types';


const NoSideBar = ()=>{return null;};

export default (props: any) => {
    const theme = useSelector((state: AppState) =>
        state.theme === 'dark' ? darkTheme : lightTheme
    );
    return (
        <Layout
            {...props}
            appBar={ NOCustomAppBar}
            sidebar={NoSideBar}
            menu={NoMenu}
            theme={theme}
        />
    );
};
