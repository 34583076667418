import React from 'react';
import Avatar from '@material-ui/core/Avatar';
//import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslate, Title } from 'react-admin';
import Button from '@material-ui/core/Button';
//import Modal from 'react-modal';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/firestore';




class SignUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    }
  }


  sign = () => {

    this.props.history.push('/Login');


  }

  handleClickEmail = (e) => {

    this.setState({ email: e.target.value });

  }

  handleClickPwd = (e) => {

    this.setState({ password: e.target.value });

  }

  handleClickFirst = (e) => {

    this.setState({ firstName: e.target.value });

  }

  handleClickLast = (e) => {

    this.setState({ lastName: e.target.value });

  }

  handleClickSignUp = () => {


    firebase
      .auth()
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then(async (res) => {

        console.log("res", res);

        var obj = {

          userId: res.user.uid,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          code: this.state.password,

        }

        firebase.firestore().collection("users").doc(res.user.uid)
          .set(obj).then(function () {
            console.log("Document successfully updated!");

          });

        // firebase.database().ref('users/' + res.user.uid).update({
        //   userId: res.user.uid,
        //   firstName: this.state.firstName,
        //   lastName: this.state.lastName,
        //   email: this.state.email,
        //   code: this.state.password,
        // });

        this.props.history.push('/Login');

      })



  }

  render() {

    console.log("this.state", this.state);

    return (
      <div>
        <div style={{ backgroundImage: `url(${require("./app_bg.jpg")})`, position: 'fixed', zIndex: 0, top: 0, left: 0, right: 0, bottom: 0 }}> </div>
        <Grid container>
          <Grid item xs={5}>
            <div style={{
              backgroundImage: `url(${require("./dg_white.png")})`,
              width: '100%',
              position: 'relative',
              backgroundRepeat: 'no-repeat',
              paddingTop: '96%',
              backgroundSize: '330px',
              backgroundPosition: 'center right'
            }}></div>
            {/* <img src= "../images/dg_white.png"/> */}
          </Grid>
          <Grid item xs={7}>
            <Container component="main" maxWidth="xs">
              <div style={{ backgroundColor: 'white', position: 'relative', marginTop: 50, paddingLeft: 30, paddingRight: 30, borderRadius: 10, width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'center', }}>
                  <Typography component="h1" variant="h5" style={{ marginTop: 20, marginLeft: '0.8rem' }}>
                    Sign Up
                  </Typography>
                </div>
                <form noValidate>

                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="firstName"
                    label="firstName"
                    //type="password"
                    id="firstName"
                    //autoComplete="current-password"
                    onChange={this.handleClickFirst}
                  />

                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="lastName"
                    label="lastName"
                    //type="password"
                    id="lastName"
                    //autoComplete="current-password"
                    onChange={this.handleClickLast}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    //autoFocus
                    onChange={this.handleClickEmail}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={this.handleClickPwd}
                  />

                  <Button
                    //type="submit"
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: '#04af86', color: 'white' }}
                    //color="#04af86"
                    //className={classes.submit}
                    onClick={this.handleClickSignUp}
                  >
                    Create Account
                </Button>
                  <Grid container style={{ marginTop: 20, paddingBottom: 20, display: 'flex', justifyContent: 'center' }}>
                    <Grid item >
                      <span style={{ cursor: 'pointer ', color: '##589cef', float: 'center', fontSize: 14, padding: 10 }}>
                        <span onClick={() => this.sign()} style={{ color: '#589cef' }}>
                          Back to Sign In</span></span>
                    </Grid>
                  </Grid>


                </form>

              </div>

            </Container>
          </Grid>
        </Grid>
      </div>
    )
  }

}

export default SignUp;