export const darkTheme = {
    palette: {
        primary: {
            main: '#90caf9',
        },
        type: 'dark', // Switching the dark mode on is a single property value change.
    },
};

export const lightTheme = {
    palette: {
        secondary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff',
        },
    },
    overrides: {
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(216, 226, 242, 1)',
                '&$disabled': {
                    backgroundColor: 'rgba(216, 226, 242, 1)',
                },
            },
        },
        RaLayout:{
            contentWithSidebar:{
                marginLeft:60
            }
        },
        RaSidebar: {
            drawerPaper: {
                marginTop: "40px !important",
                backgroundColor: 'rgba(216, 226, 242, 1)',
                color: 'rgba(0, 0, 0, 0.1)',
                height: "100%",
                position:"fixed",
                top:0,
                bottom:0,
                zIndex:10,
                boxShadow:
                    "2px 0px 1px -1px rgba(0,0,0,0.2), 1px 0px 3px 0px rgba(0,0,0,0.1)",
            },
        },
    },
};
