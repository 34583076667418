import React from 'react';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { useTranslate, Title } from 'react-admin';
import Button from '@material-ui/core/Button';
import Modal from 'react-modal';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';



class ChangePassword extends React.Component{

    constructor(props){
  super(props);
  this.state={
      oldPassword:'',
      newPassword:''
  }
 }

    handleClickopwd = (e) => {

        this.setState({oldPassword : e.target.value});

    }

    handleClicknpwd = (e) => {

        this.setState({newPassword : e.target.value});
        
    }

    doRefreshState = () =>{
    console.log("i made it here");
    this.setState({newPassword: "", oldPassword: ""})
  }


    changePassword = () => {

        //console.log("cp");

        if (firebase.auth().currentUser.email == null) {
      alert("Please add email first");
      this.setState({newPassword: "", oldPassword: ""})
       //console.log("cp1");
    }else  if (this.state.oldPassword == '' || this.state.newPassword == '') {
      alert("Please enter Both Password");
      this.setState({newPassword: "", oldPassword: ""})
       //console.log("cp2");
    } else {
         //console.log("cp3");
        var user = firebase.auth().currentUser;
        var credential = firebase.auth.EmailAuthProvider.credential(
        firebase.auth().currentUser.email,
        this.state.oldPassword
      );

      user.reauthenticateWithCredential(credential).then(() => {
        user.updatePassword(this.state.newPassword).then(() => {
          alert("Password changed successfully");
            this.setState({newPassword: "", oldPassword: ""})

          }).catch( (error) => {
          alert("Please try again");
          this.setState({newPassword: "", oldPassword: ""})
        });
      }).catch( (error) => {
        alert("Old Password Mismatch");
        this.setState({newPassword: "", oldPassword: ""})
      });

    }



    }

    render(){

         console.log("this.state",this.state);

        return(

            <Container component="main" maxWidth="xs">

                <div style={{display: 'flex',  justifyContent:'center',marginTop:50}}>

   

    <Typography component="h3" variant="h5" >
         Change Password:
        </Typography>

        </div>

           <form noValidate>

     <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="Old Password"
            label="Old Password"
            //type="password"
            id="Old Password"
            value={this.state.oldPassword}
            //autoComplete="current-password"
            onChange = {this.handleClickopwd}
          />

           <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="New Password"
            label="New Password"
            //type="password"
            value={this.state.newPassword}
            id="New Password"
            //autoComplete="current-password"
            onChange = {this.handleClicknpwd}
          />
         
         
          <Button
            //type="submit"
            fullWidth
            variant="contained"
            style={{backgroundColor:'#04af86',color:'white'}}
            //className={classes.submit}
            onClick={this.changePassword}
          >
            Save
          </Button>
           
          
        </form>
        </Container>

        )

    }

}

export default ChangePassword;