import React from 'react';
import Avatar from '@material-ui/core/Avatar';
//import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslate, Title } from 'react-admin';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
//import Modal from 'react-modal';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/firestore';



class Admin extends React.Component{

      constructor(props){
  super(props);
  this.state={
      firstName:'',
      lastName:'',
  email:'',
  password:'',
  changed:true,
  userId:'',
 firstArray : [],
 secondArray:[{

            name : '',
            description:'',
            keywords:'',
            type:'',
            fields:[],
            firebaseUid:'',
            companyName: null,

}],
  }
 this.add = this.add.bind(this);
 }

 componentDidMount() {

     var user = firebase.auth().currentUser;

     firebase.auth().onAuthStateChanged((user) => {
  if (user) {

    this.setState({userId:user.uid});

   // this.setState({secondArray[0].firebaseUid:user.uid});

  }

     })

 }

 save = () => {

    if(this.state.secondArray[0].name && this.state.secondArray[0].description && this.state.secondArray[0].keywords && this.state.secondArray[0].type){

         console.log("this.state",this.state);

         this.setState({error:''});

    this.state.secondArray[0].firebaseUid = this.state.userId;

    console.log("this.state.second save",this.state.secondArray);

    //firebase.firestore().collection('/template').add(this.state.secondArray[0]);
    firebase.firestore().collection("/template").doc("tempItem").collection(firebase.auth().currentUser.uid).add(this.state.secondArray[0]);
        
    this.props.history.push('/templateList');

    }else{

        this.setState({error:'Please fill all required fields(*)'})

    }

   


 }

 remove = (i) => {

        console.log("remove index",i);

         let testArr = this.state.firstArray;
        // let spliceArr = testArr.splice(i,1);
        //  console.log("spliceArr",spliceArr);
        this.state.firstArray.splice(i,1);
       // this.setState({firstArray:testArr.splice(i,1),testArr:this.state.firstArray.splice(i,1)});
        this.state.secondArray[0].fields.splice(i,1);
        this.setState({change:false})
        console.log("remove index",testArr,this.state);

 }

 handleClick =(event) =>{

    console.log("onChange",event.target.value);

    this.state.secondArray[0].name = event.target.value;

    this.setState({changed:false})

 }

 handleClickOne =(event) =>{

    console.log("onChange",event.target.value);

    this.state.secondArray[0].description = event.target.value;

    this.setState({changed:false})

 }

 handleClickTwo =(event) =>{

    console.log("onChange",event.target.value);

    this.state.secondArray[0].keywords = event.target.value;

    this.setState({changed:false})

 }

 handleClickThree =(event) =>{

    console.log("onChange",event.target.value);

    this.state.secondArray[0].type = event.target.value;

    this.setState({changed:false})

 }

 handleClickFirst =(index,event)=>{

    console.log("onChange index",index,event.target.value);

    this.state.secondArray[0].fields[index].name = event.target.value;

    this.setState({changed:false})

 }

 handleClickSecond =(index,event)=>{

    console.log("onChange index",index,event.target.value);

    this.state.secondArray[0].fields[index].required = event.target.value;

    this.setState({changed:false})

 }


 handleClickThird =(index,event)=>{

    console.log("onChange index",index,event.target.value);

    this.state.secondArray[0].fields[index].searchRef = event.target.value;

    this.setState({changed:false})

 }


 handleClickFourth =(index,event)=>{

    console.log("onChange index",index,event.target.value);

    this.state.secondArray[0].fields[index].type = event.target.value;

    this.setState({changed:false})

 }



fieldStruct = () => {

    return(

    <div>
         
          </div>

    )


}

 //secondArray = []



 add =()=> {
    //const documents = this.state.documents.concat(DocumentInput);
   // this.setState({ documents });

   var returnData = this.fieldStruct();
   console.log("returnData",returnData);
    const firstArray = this.state.firstArray.concat(returnData);
    this.setState({ firstArray });

   

var fieldsData = {

    name:'',
    required:'',
    searchRef:'',
    type:''

}

    this.state.secondArray[0].fields.push(fieldsData);
    
  }

  

    render(){

    //     const documents = this.state.documents.map((Element, index) => {
    //   return <Element key={ index } index={ index } />
    // });
setTimeout(()=>{

 console.log("this.state.firstArray",this.state.firstArray);
 console.log("firstArray",this.state.testArr);

},2000);
   
    console.log("this.state.secondArray",this.state.secondArray);

        return(

            <Container component="main" >

<div style={{display: 'flex',  justifyContent:'center',marginTop:50}}>

   

    <Typography component="h1" variant="h5" style={{marginLeft: '0.8rem',fontFamily:'Times New Roman'}}>
          Create Template
        </Typography>

        </div>
<br/>

<p style={{color:'red'}}>{this.state.error}</p>
<form noValidate>

    <Grid container spacing={6}>
             <Grid item xs={12} sm={6}>

     <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="firstName"
            label="Name"
            //type="password"
            id="firstName"
            //autoComplete="current-password"
            value={this.state.secondArray[0].name}
           onChange = {(event)=>this.handleClick(event)}
          />

           <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="lastName"
            label="Description"
            //type="password"
            id="lastName"
            //autoComplete="current-password"
            value={this.state.secondArray[0].description}
            onChange = {(event)=>this.handleClickOne(event)}
          />
          </Grid>
           <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Keyword"
            name="email"
           // autoComplete="email"
            //autoFocus
            value={this.state.secondArray[0].keywords}
           onChange = {(event)=>this.handleClickTwo(event)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Type"
            //type="password"
           // id="password"
            //autoComplete="current-password"
            value={this.state.secondArray[0].type}
           onChange = {(event)=>this.handleClickThree(event)}
          />
          </Grid>
          </Grid>
         <br/><br/>
         <Typography >
             <div style={{display: 'flex',  justifyContent:'center',fontSize:18,fontFamily:'Times New Roman'}}>
        Fields : &nbsp;&nbsp;
         <Button
            //type="submit"
            //color="#04af86"
            variant="contained"
            style={{width:50,height:30,backgroundColor:'#04af86',color:'white'}}
            //color="#04af86"
            //className={classes.submit}
            //onClick={this.handleClickSignUp}
            onClick={this.add}
          >
            Add
          </Button>
          </div>
        </Typography>
<br/><br/>
 <div>
        { this.state.firstArray.map((data,index) => (
            
            <div>
                {data}

                <Grid container spacing={6}>
             <Grid item xs={12} sm={6}>
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="firstName"
            label="Name"
            //type="password"
            //id="firstName"
            //autoComplete="current-password"
            value={this.state.secondArray[0].fields[index].name}
            onChange = {(event)=>this.handleClickFirst(index,event)}
          />

            <FormControl variant="outlined" style={{minWidth: 491,marginTop:16}}>
        <InputLabel id="demo-simple-select-outlined-label">Required *</InputLabel>
        <Select
       
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={this.state.secondArray[0].fields[index].required}
          onChange = {(event)=>this.handleClickSecond(index,event)}
          label="Required *"
        >
          <MenuItem value="true">True</MenuItem>
          <MenuItem value="false">False</MenuItem>
          
        </Select>
      </FormControl>

           {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="lastName"
            label="Required"
            //type="password"
            //id="lastName"
            //autoComplete="current-password"
            value={this.state.secondArray[0].fields[index].required}
            onChange = {(event)=>this.handleClickSecond(index,event)}
          /> */}
          </Grid>
          <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            //id="email"
            label="Search Ref"
            name="email"
            ///autoComplete="email"
            //autoFocus
            value={this.state.secondArray[0].fields[index].searchRef}
           onChange = {(event)=>this.handleClickThird(index,event)}
          />

           <FormControl variant="outlined" style={{minWidth: 491,marginTop:16}}>
        <InputLabel id="demo-simple-select-outlined-labels">Type</InputLabel>
        <Select
       
          labelId="demo-simple-select-outlined-labels"
          id="demo-simple-select-outlined"
          value={this.state.secondArray[0].fields[index].type}
           onChange = {(event)=>this.handleClickFourth(index,event)}
          label="Type *"
        >
          <MenuItem value="string">String</MenuItem>
          <MenuItem value="number">Number</MenuItem>
         <MenuItem value="text">Text</MenuItem>
          <MenuItem value="boolean">Boolean</MenuItem>
         
        </Select>
      </FormControl>


          {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Type"
            //type="password"
            //id="password"
            //autoComplete="current-password"
            value={this.state.secondArray[0].fields[index].type}
           onChange = {(event)=>this.handleClickFourth(index,event)}
          /> */}
          </Grid>

          
          
          </Grid>
          <Tooltip title="Delete" aria-label="Delete">
<DeleteIcon onClick={()=>this.remove(index)} style={{cursor:'pointer'}}/>
</Tooltip>

<hr/>
                </div>
          
        ))
        }

      </div>
<br/><br/>
<div style={{display: 'flex',  justifyContent:'center'}}>

          <Button
            //type="submit"
           // fullWidth
            variant="contained"
            style={{backgroundColor:'#04af86',color:'white',width:500,position: 'fixed',bottom:12}}
            //color="#04af86"
            //className={classes.submit}
            onClick={this.save}
          >
            Save
          </Button>

          </div>

         
         
          
        </form>

       </Container>


        )


    }

}

export default Admin;