import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslate, Title } from 'react-admin';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

//import Modal from 'react-modal';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/firestore';



class EditTemplate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: '',
      dataArr: [],
      target: false,
      showData: true
    }
    this.add = this.add.bind(this);
  }

  componentDidMount() {
    var user = firebase.auth().currentUser;
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.setState({ target: true })
      }
    })
  }

  add = () => {
    var fieldsData = {
      name: '',
      required: '',
      searchRef: '',
      type: ''
    }
    this.props.location.state.name.value.fields.push(fieldsData);
    this.setState({ change: false });
  }

  remove = (i) => {
    console.log("remove index", i);
    this.props.location.state.name.value.fields.splice(i, 1);
    this.setState({ change: false })
  }

  handleClickFirst = (index, event) => {
    this.props.location.state.name.value.fields[index].name = event.target.value;
    this.setState({ changed: false })
  }

  handleClickSecond = (index, event) => {
    this.props.location.state.name.value.fields[index].required = event.target.value === 'true'?true:false;
    this.setState({ changed: false })
  }

  handleClickThird = (index, event) => {
    this.props.location.state.name.value.fields[index].searchRef = event.target.value;
    this.setState({ changed: false })
  }

  handleClickFourth = (index, event) => {
    this.props.location.state.name.value.fields[index].type = event.target.value;
    this.setState({ changed: false })
  }

  save = () => {
    firebase.firestore().collection("template").doc("tempItem").collection(firebase.auth().currentUser.uid).doc(this.props.location.state.name.key)
      .set(this.props.location.state.name.value).then(function () {
        console.log("Document successfully updated!");
      });
    this.props.history.push('/templateList');
  }

  render() {
    if (this.state.showData && (this.props.location === undefined || this.props.location.state === undefined || this.props.location.state.name === undefined)) {
      this.setState({ showData: false })
      // if(this.props.location.state === undefined ){
      //setTimeout(()=> {
      this.props.history.push("/templateList");
      //},0.001)
    } else {
      return (
        <Container component="main" >
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
            <Typography component="h1" variant="h5" style={{ marginLeft: '0.8rem', fontFamily: 'Times New Roman' }}>
              Edit Template
        </Typography>
          </div>
          <br />
          <p style={{ color: 'red' }}>{this.state.error}</p>
          <form noValidate>
            {this.props.location.state.name.value ?
              <div>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="firstName"
                      label="Name"
                      //type="password"
                      id="firstName"
                      //autoComplete="current-password"
                      value={this.props.location.state.name.value.name}
                      //onChange = {(event)=>this.handleClick(event)}
                      disabled={true}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="lastName"
                      label="Description"
                      //type="password"
                      id="lastName"
                      //autoComplete="current-password"
                      value={this.props.location.state.name.value.description}
                      disabled={true}
                    // onChange = {(event)=>this.handleClickOne(event)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Keyword"
                      name="email"
                      // autoComplete="email"
                      //autoFocus
                      value={this.props.location.state.name.value.keywords}
                      disabled={true}
                    //onChange = {(event)=>this.handleClickTwo(event)}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Type"
                      //type="password"
                      // id="password"
                      //autoComplete="current-password"
                      value={this.props.location.state.name.value.type}
                      disabled={true}
                    //onChange = {(event)=>this.handleClickThree(event)}
                    />
                  </Grid>
                </Grid>
                <br /><br />
                <Typography >
                  <div style={{ display: 'flex', justifyContent: 'center', fontSize: 18, fontFamily: 'Times New Roman' }}>
                    Fields : &nbsp;&nbsp;
         <Button
                      //type="submit"
                      //color="#04af86"
                      variant="contained"
                      style={{ width: 50, height: 30, backgroundColor: '#04af86', color: 'white' }}
                      //color="#04af86"
                      //className={classes.submit}
                      //onClick={this.handleClickSignUp}
                      onClick={this.add}
                    >
                      Add
          </Button>
                  </div>
                </Typography>
                <br /><br />
                <div>
                  {this.props.location.state.name.value.fields.map((data, index) => (
                    <div>
                      <Grid container spacing={6}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="firstName"
                            label="Name"
                            //type="password"
                            //id="firstName"
                            //autoComplete="current-password"
                            value={this.props.location.state.name.value.fields[index].name}
                            onChange={(event) => this.handleClickFirst(index, event)}
                          />
                          <FormControl variant="outlined" style={{ minWidth: 491, marginTop: 16 }}>
                            <InputLabel id="demo-simple-select-outlined-label">Required *</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={this.props.location.state.name.value.fields[index].required}
                              onChange={(event) => this.handleClickSecond(index, event)}
                              label="Required *"
                            >
                              <MenuItem value="true">True</MenuItem>
                              <MenuItem value="false">False</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            //id="email"
                            label="Search Ref"
                            name="email"
                            ///autoComplete="email"
                            //autoFocus
                            value={this.props.location.state.name.value.fields[index].searchRef}
                            onChange={(event) => this.handleClickThird(index, event)}
                          />
                          <FormControl variant="outlined" style={{ minWidth: 491, marginTop: 16 }}>
                            <InputLabel id="demo-simple-select-outlined-labels">Type</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-labels"
                              id="demo-simple-select-outlined"
                              value={this.props.location.state.name.value.fields[index].type}
                              onChange={(event) => this.handleClickFourth(index, event)}
                              label="Type *"
                            >
                              <MenuItem value="string">String</MenuItem>
                              <MenuItem value="number">Number</MenuItem>
                              <MenuItem value="boolean">Boolean</MenuItem>
                              <MenuItem value="array">Array</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Tooltip title="Delete" aria-label="Delete">
                        <DeleteIcon onClick={() => this.remove(index)} style={{ cursor: 'pointer' }} />
                      </Tooltip>
                      <hr />
                    </div>
                  ))
                  }
                </div>
                <br /><br />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    //type="submit"
                    // fullWidth
                    variant="contained"
                    style={{ backgroundColor: '#04af86', color: 'white', width: 500, position: 'fixed', bottom: 12 }}
                    //color="#04af86"
                    //className={classes.submit}
                    onClick={this.save}
                  >
                    Save
          </Button>
                </div>
              </div>
              : null}
          </form>
          {this.state.target ?
            null
            :
            <CircularProgress style={{
              width: 100,
              // size:5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center', marginLeft: '45%', marginTop: 160
            }} />
          }
        </Container>
      )
    }
    return null;
  }
}

export default EditTemplate;