import React from 'react';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import Grid from '@material-ui/core/Grid';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslate, Title } from 'react-admin';
import Button from '@material-ui/core/Button';
import Modal from 'react-modal';
import * as firebase from 'firebase/app';
import 'firebase/storage';

const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};

class WorkOrderDetails extends React.Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      toggle: false,
      url: '',
      dataArr: [],
      target: false,
      error: '',
      scanning: false,
      showListOfTemplates: false,
      templates: [],
      //dataArr:[],
      gvFullTexts: [],
      gvDataSource: [],
      filename: '',
      image: '',
      imageAsUrl: '',
      setImageAsUrl: '',
      imageAsFile: '',
      setImageAsFile: '',
      allValue: [],
      winHeight: '',
      pdf: false,
    }

  }

  componentDidMount() {

    var user = firebase.auth().currentUser;

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        console.log("user", user);
        let arr = [];
        let allValue = [];
        await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(user.uid).doc(this.props.match.params.id).get().then(function (doc) {
          // querySnapshot.forEach(function (doc) {
          console.log("doc.data()", doc.data());
          if (doc.data()) {
            arr.push({ value: doc.data().fields, key: doc.data().image });
            allValue.push(doc.data())
          }
          // });
        });
        let companyId = "";

        let userId = await firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).get();
        if (arr.length == 0 && userId.data().companyId) {
          companyId = userId.data().companyId;
          await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(companyId).doc(this.props.match.params.id).get().then(function (doc) {
            console.log("companyId doc.data", doc.data());
            console.log("companyId doc.data()", doc.data().fields);
            console.log("companyId doc.data() Image", doc.data().image);
            arr.push({ value: doc.data().fields, key: doc.data().image });
            allValue.push(doc.data())
          });
        }
        console.log(" this.setState", arr, allValue);

        this.setState({ dataArr: arr, target: true, allValue: allValue })

        setTimeout(() => {
          let pdf = (allValue.length > 0 && allValue[0].imageUrl) ? allValue[0].imageUrl : this.state.dataArr[0].key
          this.setState({ pdf: pdf.includes("pdf") ? true : false })
          this.showFullImg((allValue.length > 0 && allValue[0].imageUrl) ? allValue[0].imageUrl : this.state.dataArr[0].key)

        }, 1000)


      } else {
        console.log("user not");
      }
    }).bind(this);

  }


  //   componentDidMount(){

  //     let routeState;

  //     if(this.props.location.state){
  //     localStorage.setItem('routeState', JSON.stringify(this.props.location.state))
  //     routeState = this.props.location.state
  // } else {
  //     routeState = localStorage.getItem('routeState')
  //     console.log("routeState",routeState);
  // }

  //   }



  toggle(url) {
    this.setState((prevState) => ({
      toggle: !prevState.toggle
    }));

    this.setState({ url: url, loads: false });
    //console.log("event",url);
  }

  // componentDidMount() {
  //   const { name } = this.props.location.state;
  // }

  showFullImg(filename) {
    this.setState({ loads: true })
    console.log("Inside show full image ", filename);
    let pdf = filename.includes("pdf") ? true : false
    this.setState({ pdf: pdf })
    //this.setState({ loadingFullImg: true });
    if (filename.includes("https://")) {
      this.toggle(filename);
    } else {
      let fullPath = filename.includes("pdf") ? 'pdfs/' + filename : 'images/' + filename;
      let fullPathPprp = 'images_pprp/' + filename;
      const fullImgRef = firebase.storage().ref(fullPath);
      fullImgRef.getDownloadURL()
        .then((url) => {
          this.toggle(url);
          //this.setState({ loadingFullImg: false, fullImageUrl: url, ModalVisibleStatus: true });
        }).catch(error => {
          console.log("Image not download", error);
          const fullImgRefPprp = firebase.storage().ref(fullPathPprp);
          fullImgRefPprp.getDownloadURL()
            .then((url) => {
              this.toggle(url);
              //this.setState({ loadingFullImg: false, fullImageUrl: url, ModalVisibleStatus: true });
            }).catch(error => {
              console.log("Image not download", error);
            })
        })
    }
  }
  deleteItem = async () => {
    console.log("remove file ::", this.props.match.params.id)
    await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(firebase.auth().currentUser.uid).doc(this.props.match.params.id).delete().then((querySnapshot) => {
      this.props.history.push({ pathname: '/WorkOrderList', })
    });
    let companyId = "";

    let userId = await firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).get();
    if (userId.data().companyId && userId.data().companyId.length > 0) {
      companyId = userId.data().companyId;
      await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(companyId).doc(this.props.match.params.id).delete().then((querySnapshot) => {
        this.props.history.push({ pathname: '/WorkOrderList', })
      });
    }
  }

  templateSelection = () => {

    let obj = {
      name: this.state.allValue[0].name ? this.state.allValue[0].name : this.state.allValue[0].image ? this.state.allValue[0].image : "",
      description: this.state.allValue[0].description ? this.state.allValue[0].description : "",
      type: this.state.allValue[0].type ? this.state.allValue[0].type : this.state.allValue[0].templateName ? this.state.allValue[0].templateName : "",
      keywords: this.state.allValue[0].keywords ? this.state.allValue[0].keywords : "",
      fields: this.state.allValue[0].fields,
      user: this.state.allValue[0].user
    }
    console.log("templateSelection ", this.state.allValue)
    setTimeout(() => {      
      this.props.history.push({
        pathname: '/template',
        state: {
          pdf: this.state.pdf,
          user: this.state.allValue[0].user,
          woId: this.props.match.params.id,
          template: obj,
          selectedInvoice: this.state.allValue[0].selectedInvoice ? this.state.allValue[0].selectedInvoice : "",
          doc_Validation: this.state.allValue[0].doc_Validation,
          filename: this.state.allValue[0].imageUrl ? this.state.allValue[0].imageUrl : this.state.allValue[0].image,
          gvDataSource: this.state.allValue[0].googleVisionAllData,
          gvFullTexts: this.state.allValue[0].googleVisionFullText && this.state.allValue[0].googleVisionFullText.length > 0 ? this.state.allValue[0].googleVisionFullText : this.state.allValue[0].googleVisionSelectedData
        }
      })
    }, 2000);
  }

  render() {
    var modal = [];
    modal.push(
      <div style={{ top: 20, left: 0 }}>
        <div className="modal" style={this.state.toggle ? display : hide}>
          <div className="modal-content" style={{ display: 'flex', justifyContent: 'center' }}>
            {this.state.url ?
              this.state.pdf ?
                <iframe src={this.state.url} width="100%" height="450"></iframe>
                : <img src={this.state.url} style={{ marginTop: 20, width: 400, height: 350 }} />
              : <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
                {/* <CircularProgress color="secondary" /> */}
                <CircularProgress color="secondary" />
              </div>}
          </div>
          <div className="modal-footer">

          </div>
        </div>
      </div>
    );


    return (
      <div>
        {this.state.loading ?

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 150 }}>
            {/* <CircularProgress color="secondary" /> */}
            <CircularProgress color="secondary" />
          </div>

          :
          <div>
            {this.state.dataArr[0] ?
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <img src={require("./dg_full_resolution.png")} style={{ width: '13%', padding: 10 }} />
                  <p style={{ float: 'right', fontWeight: 600, fontSize: 18 }}>Document Details:</p>
                </Grid>
                <Grid item sm={6}>
                  <Button style={{ float: 'right', backgroundColor: '#04af86', width: '20%', marginTop: 10, marginLeft: 10, borderRadius: 5 }}
                    variant="contained" color="primary" onClick={this.deleteItem} >
                    Delete
                  </Button>
                  <Button style={{ float: 'right', backgroundColor: '#04af86', width: '20%', marginTop: 10, borderRadius: 5 }}
                    variant="contained" color="primary" onClick={this.templateSelection} >
                    Edit
                  </Button>
                </Grid>
              </Grid>
              : null}

            <br />

            <Grid container spacing={2}>

              <Grid item xs={12} sm={8}>

                {this.state.loads ?

                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: 150 }}>
                    {/* <CircularProgress color="secondary" /> */}
                    <CircularProgress color="secondary" />
                  </div>

                  :

                  <div style={{ padding: 10 }}>

                    <div className="modal" style={this.state.toggle ? display : hide}>
                      <div className="modal-content" style={{ border: '1px solid #bdb0b0', height: window.innerHeight + 'px', overflowY: 'scroll' }}>
                        {this.state.url ?
                          this.state.pdf ?
                            <iframe src={this.state.url} width="100%" height="450"></iframe>
                            :
                            <img src={this.state.url} style={{ width: '100%' }} />
                          : <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
                            {/* <CircularProgress color="secondary" /> */}
                            <CircularProgress color="secondary" />
                          </div>}
                      </div>
                      <div className="modal-footer">

                      </div>
                    </div>

                  </div>
                }

              </Grid>
              <Grid item xs={12} sm={4}>
                <div style={{ marginTop: 10, height: window.innerHeight + 'px', overflowY: 'scroll' }}>
                  <Table size="small" style={{ height: '100%' }} >
                    {this.state.dataArr.length > 0 ?
                      <TableBody>
                        {this.state.dataArr[0].value.map(dataTwo => (
                          <TableRow>
                            <TableCell>

                              <b style={{ color: '#0f5aa9' }}> {dataTwo.name}</b> : {dataTwo.value}


                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>

                      : null}

                  </Table>
                </div>

              </Grid>
            </Grid>
          </div>
        }
      </div>




    )

  }

}

export default WorkOrderDetails;