import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import _ from "underscore";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslate, Title } from 'react-admin';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddCircle from '@material-ui/icons/AddCircle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CopyToClipboard } from 'react-copy-to-clipboard';
//import { Draggable, Droppable } from 'react-drag-and-drop';
//import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Modal from '@material-ui/core/Modal';
import { Document, Page } from 'react-pdf';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/database';
const fetch = require('node-fetch');

class TemplateInfo extends React.Component {
  constructor(props) {
    super(props);
    this.rotate = this.rotate.bind(this);
    this.rotateleft = this.rotateleft.bind(this);
    this.state = {
      res: '',
      url: '',
      rotation: 0,
      toggle: false,
      userCredential: firebase.auth().currentUser,
      arr: [],
      errorStatus: false,
      templateData: {},
      fullTexts: [],
      textData: '',
      checked: false,
      toolBox: false,
      searchText: '',
      filterArrText: [],
      copied: false,
      loader: true,
      allData: [],
      dataArr: [],
      pageNumber: 1,
      selectedInvoice: "",
      openModalPDF:false,
      pdf: false
    }
    if (this.props.location === undefined || this.props.location.state === undefined) {
      setTimeout(() => {
        this.props.history.push("/ImageDataExtract");
      }, 100)
    } else {

      let fullText = this.props.location.state.gvFullTexts ? this.props.location.state.gvFullTexts : [];
      let template = this.props.location.state.template;
      let totalFields = template.fields.length;

      template.fields.map((field, index) => {
        if (index <= totalFields / 2) {
          field['scrollTo'] = 0
        }
        if (index > totalFields / 2) {
          field['scrollTo'] = 200
        }
        return field;
      });
      this.toggle = this.toggle.bind(this);
      this.state = {
        res: '',
        url: '',
        rotation: 0,
        toggle: false,
        userCredential: firebase.auth().currentUser,
        arr: [],
        errorStatus: false,
        templateData: template,
        fullTexts: fullText,
        textData: '',
        checked: this.props.location.state.doc_Validation ? this.props.location.state.doc_Validation : false,
        toolBox: false,
        searchText: '',
        filterArrText: [],
        copied: false,
        loader: true,
        allData: [],
        dataArr: [],
        pageNumber: 1,
        pdf: this.props && this.props.location && this.props.location.state && this.props.location.state.pdf ? this.props.location.state.pdf : false,
        selectedInvoice: this.props.location.state.selectedInvoice ? this.props.location.state.selectedInvoice : "",
      }
    }
  }
  // componentWillUnmount() {
  //   document.removeEventListener('dragstart');
  // }
  componentDidMount() {
    this.setState({ loader: true })
    document.addEventListener('dragstart', (event) => {
      event.dataTransfer.setData('Text', event.target.innerHTML);
      this.setState({ change: true })
    });
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        let arr = [];
        await firebase.firestore().collection("template").doc("tempItem").collection(user.uid).get().then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            arr.push(doc.data())
          });
        });
        await firebase.firestore().collection("template").doc("tempItem").collection("97nVh7z2EQMoaNhrANS8vmKJYak2").get().then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            arr.push(doc.data())
          });
        });
        setTimeout(() => {
          let stateObj=JSON.parse(localStorage.getItem('tempstate'))
          console.log("this.props.location.state :: ", stateObj)
          this.setState({ dataArr: arr, target: true }, () => {
            this.showList();
            if(this.props.location && this.props.location.state && this.props.location.state.filename){
              this.showFullImg(this.props.location.state.filename);
              this.setState({ pdf: this.props.location.state.pdf, loader: false, selectedInvoice: this.props.location.state.selectedInvoice ? this.props.location.state.selectedInvoice : "" })
            }else{
              let fileName=localStorage.getItem('imageName')
              console.log("FileName ::",fileName)
              this.showFullImg(fileName)
              this.setState({ pdf: stateObj.pdf, loader: false, selectedInvoice: stateObj.selectedInvoice ? stateObj.selectedInvoice : "" })
            }
          })
        }, 5000);
      }
    })
  }

  showList = () => {

    var user = firebase.auth().currentUser;
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        let allData = await firebase.firestore().collection("dropDowns").doc("rGoQdYula8rYMLUTi1Fv").get().then((querySnapshot) => {
          console.log("rGoQdYula8rYMLUTi1Fv", querySnapshot.data().invoiceSelection)
          this.setState({ allData: querySnapshot.data().invoiceSelection })
        })
      }
    })
  }

  toggle(url) {
    this.setState((prevState) => ({
      toggle: !prevState.toggle
    }));
    this.setState({ url: url });
  }

  showFullImg(filename) {
    console.log("this state pdf ", this.state.pdf)
    let pdf = filename.includes("pdf") ? true : false
    this.setState({ pdf: pdf })
    if (filename.includes("https://")) {
      this.toggle(filename);
    } else {
      let fullPath = filename.includes("pdf") ? 'pdfs/' + filename : 'images/' + filename;
      let fullPathPprp = 'images_pprp/' + filename;
      const fullImgRef = firebase.storage().ref(fullPath);
      fullImgRef.getDownloadURL()
        .then((url) => {
          this.toggle(url);
        }).catch(error => {
          console.log("Image not download", error);
          const fullImgRefPprp = firebase.storage().ref(fullPathPprp);

          fullImgRefPprp.getDownloadURL()
            .then((url) => {
              this.toggle(url);
            }).catch(error => {
              console.log("Image not download", error);
            })
        })
    }
  }
  openModalPDF = ()=>{
    this.setState({toolBox:true})
  }
  handleClose = ()=>{
    this.setState({openModalPDF:false})
  }
  saveData = async () => {
    this.setState({ changed: true });
    let data = this.state.templateData.fields;
    let requiredFields = data.filter((field) => {
      return field.required && !field.value
    })

    let selectedTexts = this.state.fullTexts.filter((txt) => {
      return txt.isSelected;
    });

    if (requiredFields.length > 0) {
      let fields = [];
      requiredFields.map((fld) => {
        fields.push(fld.name);
      })
      this.setState({ errorStatus: true, error: fields + ' are required' });
    } else {
      let gvDataSource = this.props.location.state.gvDataSource;
      //let gvFullTexts = this.props.location.state.gvFullTexts;
      let gvFullText = gvDataSource && gvDataSource.fullTextAnnotation ? gvDataSource.fullTextAnnotation : {};
      let docWidth = gvFullText && gvFullText.pages ? gvFullText.pages[0].width : 0;
      let docHeight = gvFullText && gvFullText.pages ? gvFullText.pages[0].height : 0;

      let dataToInsert = {
        user: this.state.userCredential.uid,
        userEmail: firebase.auth().currentUser.email,
        doc_Validation: this.state.checked,
        selectedInvoice: this.state.selectedInvoice,
        //templateName: this.props.location.state.template.name,
        templateName: this.state.templateData.name,
        name: this.state.templateData.name,
        description: this.state.templateData.description,
        type: this.state.templateData.type,
        keywords: this.state.templateData.keywords,
        fields: data,
        image: this.props.location.state.filename,
        //imageUrl: this.props.navigation.state.params.imageUrl,
        googleVisionSelectedData: selectedTexts,
        // googleVisionAllData: this.props.location.state.gvDataSource,
        googleVisionFullText: this.props.location.state.gvFullTexts,
        createdOn: new Date().toISOString(),
      }
      let editDataToInsert = {
        createdByuser: this.state.userCredential.uid,
        createdByUserEmail: firebase.auth().currentUser.email,
        doc_Validation: this.state.checked,
        selectedInvoice: this.state.selectedInvoice,
        //templateName: this.props.location.state.template.name,
        templateName: this.state.templateData.name,
        name: this.state.templateData.name,
        description: this.state.templateData.description,
        type: this.state.templateData.type,
        keywords: this.state.templateData.keywords,
        fields: data,
        // image: this.props.location.state.filename,
        //imageUrl: this.props.navigation.state.params.imageUrl,
        googleVisionSelectedData: selectedTexts,
        // googleVisionAllData: this.props.location.state.gvDataSource ? this.props.location.state.gvDataSource : {},
        googleVisionFullText: this.props.location.state.gvFullTexts,
        updatedOn: new Date().toISOString(),
      }

      let selectedFields = {
        templateName: this.state.templateData.name,
        docWidth,
        docHeight
      };
      if (this.props.location.state.user === firebase.auth().currentUser.uid) {
        if (this.props.location.state.woId) {
          await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(firebase.auth().currentUser.uid).doc(this.props.location.state.woId)
            .update(editDataToInsert)
          // .then(ref => {
          //   console.log("editDataToInsert ref id", ref);
          // })
          this.setState({ changed: false });

          this.props.history.push('/WorkOrderList');
        } else {
          await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(firebase.auth().currentUser.uid).add(dataToInsert)
            .then(ref => {
              console.log("current user dataToInsert ref id", ref.id);
            })
          setTimeout(() => {
            this.resetImage(dataToInsert);
          }, 3000);
        }
      }
      else {
        await firebase.firestore().collection("WorkOrders").doc("WoItem").collection(this.props.location.state.user).doc(this.props.location.state.woId)
          .update(editDataToInsert)
          .then(ref => {
            console.log("Company user editDataToInsert ref id", ref);
          })
        this.setState({ changed: false });
        this.props.history.push('/WorkOrderList');
      }
    }
  }

  resetImage = (dataToInsert) => {
    setTimeout(() => {
      this.sendWorkorderEmail(dataToInsert);
    }, 300)
  }

  async sendWorkorderEmail(woData) {
    let url = 'https://us-central1-imagedataextract.cloudfunctions.net/sendWorkorderEmail';
    let body = await JSON.stringify({
      "data": woData
    });
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    });
    const res = await response.json();
    this.setState({ changed: false });
    this.props.history.push('/WorkOrderList');
  }

  validate = () => {
    this.setState({ checked: !this.state.checked })
  }

  handleTooltipClose = () => {
    this.setState({ toolBox: false })
  };

  handleTooltipOpen = () => {
    this.setState({ toolBox: true })
  };

  searchText = (event) => {
    this.state.filterArrText = [];
    _.each(this.state.fullTexts, (resDat) => {
      if (resDat.value.toLowerCase().includes(event.target.value.toLowerCase()) == true) {
        this.state.filterArrText.push(resDat);
      }
    })
    this.setState({ searchText: event.target.value })
  }

  copy = () => {
    this.setState({ copied: true });
  };

  handleChange = (event) => {
    this.setState({ selectedInvoice: event.target.value })
  }

  rotate() {
    let newRotation = this.state.rotation + 90;
    if (newRotation >= 360) {
      newRotation = - 360;
    }
    this.setState({
      rotation: newRotation,
    })
  }

  rotateleft() {
    let newRotation = this.state.rotation - 90;
    if (newRotation >= 360) {
      newRotation = - 360;
    }
    this.setState({
      rotation: newRotation,
    })
  }
  templateSelection = (item) => {
    this.setState({ templateData: item })
  }
  render() {
    if (this.state.loader) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, padding: 20 }}>
          <CircularProgress style={{ padding: 20 }} color="secondary" />
        </div>
      )
    }

    return (
      <div>
         <Modal
        open={this.state.openModalPDF}
        onClose={this.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{marginTop:20,}}
      >
        <iframe src={this.state.url} marginTop="20px" width="80%" height="450"></iframe> 
      </Modal>   
        <Grid container spacing={2} style={{ paddingTop: 8 }}>
          <Grid item sm={4}>
            <img src={require("../workOrderList/dg_full_resolution.png")} style={{ float: 'left', width: '23%', padding: 10 }} />
            {/* <p>Change Template: </p> */}
            <span style={{ float: 'right', padding: 12, color: '#949495' }}>Change Template:</span>
          </Grid>
          {this.state.dataArr.length > 0 && this.state.dataArr.map(dat => (
            <Grid key={dat.name} item sm={1.5}>
              {dat.name === this.props.location.state.template.name ? null :
                <div style={{ borderStyle: 'solid', marginTop: 5, width: 100, borderColor: '#04af86', borderRadius: 5, backgroundColor: '#04af86', color: 'white', display: 'block', marginRight: 6, paddingLeft: 8, paddingRight: 8, cursor: 'pointer' }}>
                  <ListItemText
                    disableTypography
                    onClick={() => this.templateSelection(dat)}
                    style={{ textAlign: 'center' }}
                    primary={<Typography type="body2" style={{ fontSize: '12px' }}>{dat.name}</Typography>}
                  //primary={dat.name}
                  />
                </div>}
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={2} sm={4}>
          </Grid>
          <Grid item xs={2} sm={2}>
            <p style={{ textAlign: 'right', fontWeight: 600, fontSize: 18 }}><b>Drag Drop and Hold</b></p>
          </Grid>
          <Grid item xs={2} sm={6}>
            <div style={{ float: 'right' }}>
              <FormControl variant="outlined" style={{ minWidth: 170 }} margin='dense'>
                <InputLabel htmlFor='selected-language'>Invoice Selection</InputLabel>
                <Select
                  size="small"
                  defaultValue={this.props.location.state.selectedInvoice}
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  label="Invoice Selection"
                  value={this.state.selectedInvoice}
                  onChange={(event) => this.handleChange(event)}
                >
                  <MenuItem value={""}></MenuItem>
                  {this.state.allData.length > 0 && this.state.allData.map((dropData) => (
                    <MenuItem value={dropData}>{dropData}</MenuItem>
                  ))}

                </Select>
              </FormControl>
      &nbsp;&nbsp;&nbsp;
              <TextField
                label="Search Text"
                id="outlined-size-small"
                //defaultValue="Small"
                variant="outlined"
                size="small"
                value={this.state.searchText}
                onChange={this.searchText}
                style={{ marginTop: 8, width: '31%' }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ marginTop: 8 }}
                    checked={this.state.checked}
                    onChange={this.validate}
                    color="primary"
                  //inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={<span style={{ marginTop: 12, display: 'inline-block' }}>Validate</span>}

              />

              {/* } */}
              <Button
                variant="contained"
                style={{ marginTop: 8, backgroundColor: '#04af86', color: 'white' }}
                onClick={this.saveData}
              >
                Save
          </Button>
              {/* </div> */}
            </div>
          </Grid>
        </Grid>
        <hr />
        {this.state.changed ?
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, padding: 20 }}>
            {/* <CircularProgress color="secondary" /> */}
            <CircularProgress style={{ padding: 20 }} color="secondary" />
          </div>

          : null}
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            {this.state.url ?
              <div style={{ height: window.innerHeight + 'px', overflowY: 'scroll' }}>
                <ClickAwayListener onClickAway={this.handleTooltipClose}>
                  <Tooltip
                    // style={{height:}}
                    title={this.state.pdf ?
                      <iframe src={this.state.url} width="200%" height="450"></iframe> :
                      <img src={this.state.url} style={{ transform: `rotate(${this.state.rotation}deg)`, width: '350%', height: '100%', overflowY: 'scroll' }} />}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    onClose={this.handleTooltipClose}
                    //onOpen={this.handleOpen}
                    open={this.state.toolBox}
                  >
                    <div>
                      {this.state.pdf ?
                        <input onClick={()=> window.open(this.state.url, '_blank')} type="button" value="Full Screen" />
                        : <input onClick={this.rotateleft} type="button" value="left" />}
                      {!this.state.pdf && <input style={{ marginLeft: 20 }} onClick={this.rotate} type="button" value="right" />}
                      {this.state.pdf ?
                        <iframe src={this.state.url} width="100%" height="450"></iframe>
                        : <img src={this.state.url} onClick={this.handleTooltipOpen} style={{ transform: `rotate(${this.state.rotation}deg)`, width: '100%' }} />}
                    </div>
                  </Tooltip>
                </ClickAwayListener>
              </div>
              :
              <div style={{ transform: `rotate(${this.state.rotation}deg)` }} style={{ display: 'flex', justifyContent: 'center', marginTop: 150 }}>

                <CircularProgress color="secondary" />
              </div>

            }
          </Grid>

          {(this.state.filterArrText && this.state.filterArrText.length > 0) ?

            <Grid item xs={12} sm={4}>

              {this.state.filterArrText ?

                <div style={{ height: window.innerHeight + 'px', overflowY: 'scroll' }}>
                  <div style={{ height: '100%' }}>
                    {this.state.filterArrText.map((dataO, index) => (

                      <Tooltip title={<p style={{ fontSize: 13 }}>{dataO.value}</p>}
                        placement="top"
                      //onMouseOver={() => this.handleOpen(index)}
                      //onMouseLeave={() => this.handleClose(index)}
                      //onClose={this.handleClose} 
                      //onOpen={this.handleOpen}
                      //open={this.state.toolBox}
                      //style={{fontSize:14,border: '1px solid #dadde9',backgroundColor: '#f5f5f9',color: 'rgba(0, 0, 0, 0.87)'}}
                      >
                        <CopyToClipboard onCopy={this.copy} text={dataO.value}>
                          <button style={{ border: 0, backgroundColor: '#04af86', borderRadius: 5, margin: '2px', color: 'white' }}
                          //onClick={()=>this.copy(dataO.value)}
                          >
                            <p draggable="true" style={{ height: 40, width: 80, overflow: 'hidden', scrollbarWidth: 'thin', scrollbarColor: '#04af86 #04af86' }}>
                              {dataO.value}
                            </p>
                          </button>
                        </CopyToClipboard>
                      </Tooltip>
                    ))}
                  </div>
                </div>
                : null}
            </Grid>
            :
            <Grid item xs={12} sm={4}>
              {this.state.fullTexts ?
                <div style={{ height: window.innerHeight + 'px', overflowY: 'scroll' }}>
                  <div style={{ height: '100%' }}>
                    {this.state.fullTexts.map((dataO, index) => (
                      <Tooltip key={index} title={<p style={{ fontSize: 13 }}>{dataO.value}</p>}
                        placement="top"
                        onMouseOver={() => this.handleOpen(index)}
                        onMouseLeave={() => this.handleClose(index)}
                      //onClose={this.handleClose} 
                      //onOpen={this.handleOpen}
                      //open={this.state.toolBox}
                      //style={{fontSize:14,border: '1px solid #dadde9',backgroundColor: '#f5f5f9',color: 'rgba(0, 0, 0, 0.87)'}}
                      >
                        <CopyToClipboard onCopy={this.copy} text={dataO.value}>
                          <button
                            style={{ margin: '2px', border: 0, backgroundColor: '#04af86', borderRadius: 5, color: 'white' }}
                          //onClick={()=>this.copy(dataO.value)} 
                          >
                            <p draggable="true" style={{ height: 40, width: 80, overflow: 'hidden', scrollbarWidth: 'thin', scrollbarColor: '#04af86 #04af86' }}>
                              {dataO.value}
                            </p>
                          </button>
                        </CopyToClipboard>
                      </Tooltip>
                    ))}
                  </div>
                </div>
                : null}
            </Grid>
          }
          <Grid item xs={12} sm={4}>
            {this.state.templateData ?
              <List style={{ height: window.innerHeight + 'px', overflowY: 'scroll' }}>
                <div style={{ height: '100%' }}>
                  {this.state.templateData.fields.map((dataOne, index) => (
                    <div>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          disableTypography
                          //onClick={this.templateSelection(dataOne)}
                          primary={<Typography style={{ fontWeight: 700, color: '#0f5aa9' }}>{dataOne.name}
                            {dataOne.type == 'array' && <IconButton style={{ color: "#04af86" }} aria-label="Add rows" component="span"
                              onClick={() => dataOne.value && dataOne.value.length > 0 ? dataOne.value[dataOne.value.length] = "" : dataOne.value = [""]} >
                              <AddCircle />
                            </IconButton>}
                          </Typography>}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                              //className={classes.inline}
                              //color="textPrimary"
                              >
                                {dataOne.type == 'array' ? dataOne.value && dataOne.value.length > 0 && dataOne.value.map((data, indx) => (
                                  <TextField
                                    style={{ width: 400 }}
                                    // id="target"
                                    //ondrop="drop_handler(event);"              
                                    defaultValue={data}
                                    value={data}
                                    //style={{borderColor: "white !important"}} 
                                    InputProps={{ disableUnderline: false }}
                                    onChange={(event) => {
                                      if (this.state.change) {
                                        let newEvent = event.target.value.replace(this.state.templateData.fields[index].value, '')
                                        console.log("text", event.target.value, this.state.templateData.fields[index], newEvent);
                                        this.state.templateData.fields[index].value[indx] = newEvent;
                                        this.setState({ change: false, })
                                        console.log("text to save", this.state)
                                        event.target.value = newEvent
                                      } else {
                                        this.state.templateData.fields[index].value[indx] = event.target.value;
                                        this.setState({ change: false, })
                                      }
                                    }}
                                  />)) :
                                  <TextField
                                    style={{ width: 400 }}
                                    // id="target"
                                    //ondrop="drop_handler(event);"              
                                    defaultValue={dataOne.value}
                                    value={dataOne.value}
                                    //style={{borderColor: "white !important"}} 
                                    InputProps={{ disableUnderline: true }}
                                    onChange={(event) => {
                                      if (this.state.change) {
                                        let newEvent = event.target.value.replace(this.state.templateData.fields[index].value, '')
                                        console.log("text", event.target.value, this.state.templateData.fields[index], newEvent);
                                        this.state.templateData.fields[index].value = newEvent;
                                        this.setState({ change: false, })
                                        console.log("text to save", this.state)
                                        event.target.value = newEvent
                                      } else {
                                        this.state.templateData.fields[index].value = event.target.value;
                                        this.setState({ change: false, })
                                      }
                                    }}
                                  />}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider component="li" />
                    </div>
                  ))}
                </div>
              </List>
              : null}
            {this.state.errorStatus ?
              <div style={{ marginLeft: 10 }}>
                <p style={{ color: "#FF0000", fontSize: 15 }}>Error!!</p>
                <p style={{ color: "#FF0000", fontSize: 15 }}>{this.state.error}</p>
              </div>
              : null}
          </Grid>
        </Grid>
        <br /><br />
      </div>
    )
  }
}

export default TemplateInfo;